import { useDisclosure } from '@chakra-ui/react';
import { InitialUserAchievements, InitialUserFeatures, UserFlags } from '@playful/api';
import { AppBarSection } from '@playful/design_system/AppBar';
import Logo from '@playful/design_system/assets/logoSmall.svg';
import { Box } from '@playful/design_system/Box';
import { IconButton, RouteButton } from '@playful/design_system/Button';
import { Divider } from '@playful/design_system/Divider';
import { PlusIcon } from '@playful/design_system/Icon';
import { Menu, MenuButton, MenuItem, MenuList } from '@playful/design_system/Menu';
import { useIsSmallScreen } from '@playful/design_system/utils';
import { humanizePropertyName } from '@playful/runtime/propertiesHelpers';
import { buildUserRoute } from '@playful/utils';
import React from 'react';
import { useLocation } from 'wouter';

import { useApp } from '../AppContext';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { ExplorerHeader } from '../layouts/ExplorerLayout';
import { ROUTE_GALLERY, ROUTE_HOME, ROUTE_REGISTER, ROUTE_SIGNIN } from '../routes';
import { HelpMenu } from '../workbench/HelpMenu';
import { Avatar } from './Avatar';
import { useUserContext } from './UserContext';

export function AccountBar(props: { hideLogo?: boolean }) {
  const { hideLogo } = props;
  const { user, signOut, isLoggedIn, previousUserEmail } = useUserContext();
  const [location, setLocation] = useLocation();

  const {
    isOpen: isAccountMenuOpen,
    onOpen: onAccountMenuOpen,
    onClose: onAccountMenuClose,
  } = useDisclosure();

  const app = useApp();
  const isSmallScreen = useIsSmallScreen();
  return (
    <AppBarSection
      width='full'
      justifyContent={isLoggedIn ? 'end' : 'space-between'}
      id='account-bar'
      spacing={isSmallScreen ? 1 : 2}
    >
      {isLoggedIn ? (
        <>
          <HelpMenu />
          <CTAButton
            size={isSmallScreen ? 'sm' : 'md'}
            color='gray.900'
            leftIcon={<PlusIcon display={{ base: 'none', lg: 'block' }} />}
            pl={3}
            ml={0}
            onClick={app.newProject}
            fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
          >
            new project
          </CTAButton>

          <Menu
            id='menu-appbar'
            closeOnBlur
            autoSelect={false}
            isOpen={isAccountMenuOpen}
            onClose={onAccountMenuClose}
          >
            <MenuButton
              as={IconButton}
              data-cy='account-button'
              aria-label='My Account'
              aria-owns={isAccountMenuOpen ? 'menu-appbar' : undefined}
              aria-haspopup='true'
              variant='ghost'
              onClick={onAccountButtonClick}
              colorScheme='whiteAlpha'
              isRound
            >
              <Avatar user={user} />
            </MenuButton>
            <MenuList bg={'white'} color={'gray.900'} minWidth={0}>
              <MenuItem onClick={onProfileClick}>My Profile</MenuItem>
              <Divider />
              <MenuItem data-cy='sign-out' onClick={onSignOutClick}>
                Sign out {user.name}
              </MenuItem>
              <Divider key='divider-features' />
              {/* TODO: Hide for non-admins? Non-@playful users? */}
              {Object.keys(InitialUserFeatures)
                .filter((feature) => !feature.startsWith('achievement'))
                .map((feature) => (
                  <UserFeatureMenuItem key={feature} flag={feature as keyof UserFlags} />
                ))}
              <Divider key='divider-achievements' />
              {Object.keys(InitialUserAchievements)
                .filter((feature) => feature.startsWith('achievement'))
                .map((feature) => (
                  <UserFeatureMenuItem key={feature} flag={feature as keyof UserFlags} />
                ))}
            </MenuList>
          </Menu>
        </>
      ) : (
        <>
          {!hideLogo && (
            <Box
              as={Logo}
              height={8}
              width={'100px'}
              minWidth={'92px'}
              maxWidth='full'
              display={'block'}
              xmlns='http://www.w3.org/2000/svg'
            />
          )}

          <ExplorerHeader
            activePath={location}
            navItems={[
              { path: ROUTE_HOME, label: 'home' },
              { path: ROUTE_GALLERY, label: 'community' },
            ]}
          />
          <div>
            <RouteButton
              data-cy='sign-up'
              href={previousUserEmail ? ROUTE_SIGNIN : ROUTE_REGISTER}
              size={isSmallScreen ? 'sm' : 'md'}
              fontSize={{ base: 'sm', md: 'md' }}
              color={'gray.700'}
              colorScheme='accent'
            >
              {previousUserEmail ? `sign in` : `sign up`}
            </RouteButton>
          </div>
        </>
      )}
    </AppBarSection>
  );

  async function onSignOutClick() {
    onAccountMenuClose();
    signOut();
  }

  function onProfileClick() {
    onAccountMenuClose();
    setLocation(buildUserRoute(user.name));
  }

  function onAccountButtonClick(event: React.MouseEvent<HTMLElement>) {
    onAccountMenuOpen();
  }
}

function UserFeatureMenuItem(props: { flag: keyof UserFlags }) {
  const { flag } = props;
  const { hasFlag, toggleUserFlag } = useUserContext();
  return (
    <MenuItem key={`${flag}-feature-toggle`} onClick={toggleFeatureClick(flag as any)}>
      {`${hasFlag(flag as any) ? 'Disable' : 'Enable'} ${humanizePropertyName(flag)}`}
    </MenuItem>
  );
  function toggleFeatureClick(flagName: keyof UserFlags) {
    return () => {
      toggleUserFlag(flagName);
    };
  }
}
