import { BackdropProps, Dialog, Paper, Popper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import { useIsSmallScreen } from '@playful/design_system/utils';
import React, { FunctionComponent, useEffect } from 'react';

import {
  DialogActionIconButton,
  DialogActionIconButtonGroup,
  DialogActions,
} from '../Modal/DialogActions';
import { DialogCardContent } from '../Modal/DialogContent';
import { DialogStep, DialogStepper } from '../Modal/DialogStepper';
import { DialogCardTitle } from '../Modal/DialogTitle';
import { Blocker } from './Blocker';
import type { TutorialState } from './types';
import { useTutorialStepStyles } from './useTutorialStyles';

type TutorialStepProps = {
  setActiveStep: (step: number) => void;
  onFinishTutorial: (tutorial: TutorialState<any>) => void;
  tutorial: TutorialState<any>;
};

export const TutorialStep: FunctionComponent<TutorialStepProps> = ({
  onFinishTutorial,
  setActiveStep,
  tutorial,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const theme = useTheme();

  const { activeStepIndex, callouts, steps } = tutorial;

  const { calloutOptions, color, header: Header, text, title } = steps[activeStepIndex];

  const { desktopPlacement, mobilePlacement, name: calloutName } = calloutOptions ?? {};

  const anchor = calloutName && callouts[calloutName];

  const placement = isSmallScreen ? mobilePlacement : desktopPlacement;

  const classes = useTutorialStepStyles({
    hasAnchor: !!anchor,
    isMobileSafari: /^((?!chrome|android).)*(iphone|ipad|ipod).*(safari)/i.test(
      navigator.userAgent,
    ),
    isSmallScreen,
  });

  const handleDoneClick = () => {
    onFinishTutorial(tutorial);
  };

  const handleNextClick = () => {
    setActiveStep(activeStepIndex + 1);
  };

  const handlePreviousClick = () => {
    setActiveStep(activeStepIndex - 1);
  };

  useEffect(() => {
    const memo = tutorial.params?.onShow?.(tutorial);

    return () => tutorial.params?.onHide?.(memo);
  }, [tutorial.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const card = (
    <>
      <DialogCardTitle>
        <Header color={color} />
      </DialogCardTitle>
      <DialogCardContent title={title}>
        <Typography
          variant={'body1'}
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: '#6F6F6F',
            lineHeight: '28px',
            fontStyle: 'normal',
          }}
        >
          {text}
        </Typography>
      </DialogCardContent>
      <DialogActions>
        <DialogStepper activeStep={activeStepIndex}>
          {steps.map((_: any, i: number) => (
            <DialogStep
              color={color}
              key={`step-${i}`}
              onClick={() => setActiveStep(i)}
              completed={false}
              active={activeStepIndex === i}
            />
          ))}
        </DialogStepper>
        <DialogActionIconButtonGroup>
          {activeStepIndex !== 0 && (
            <DialogActionIconButton
              backgroundColor={theme.palette.action.disabled}
              onClick={handlePreviousClick}
              style={{ color: theme.palette.text.primary }}
            >
              <ChevronLeftIcon fontSize='small' />
            </DialogActionIconButton>
          )}
          {activeStepIndex + 1 < steps.length && (
            <DialogActionIconButton backgroundColor={color} onClick={handleNextClick}>
              <ChevronRightIcon fontSize='small' />
            </DialogActionIconButton>
          )}
          {activeStepIndex + 1 === steps.length && (
            <DialogActionIconButton backgroundColor={color} onClick={handleDoneClick}>
              <DoneIcon fontSize='small' />
            </DialogActionIconButton>
          )}
        </DialogActionIconButtonGroup>
      </DialogActions>
    </>
  );

  return anchor ? (
    <>
      <Blocker fixedPosition key='blocker' open transitionDuration={0} tutorial={tutorial} />
      <Popper
        anchorEl={anchor.current}
        className={classes.card}
        disablePortal={true}
        open={true}
        placement={placement}
        modifiers={{
          preventOverflow: {
            boundariesElement: 'viewport',
          },
        }}
      >
        <Paper className={classes.paper}>{card}</Paper>
      </Popper>
    </>
  ) : (
    <Dialog
      BackdropComponent={(props: BackdropProps) => (
        <Blocker key='blocker' tutorial={tutorial} {...props} />
      )}
      BackdropProps={{ transitionDuration: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.card}
      disablePortal
      open
      transitionDuration={0}
    >
      {card}
    </Dialog>
  );
};
