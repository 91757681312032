import type { ButtonProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/react';
import { Button } from '@playful/design_system/Button';
import React from 'react';

export const CTAButton: React.FunctionComponent<ButtonProps> = forwardRef<ButtonProps, any>(
  (props, forwardedRef) => {
    const { children } = props;
    return (
      <Button colorScheme='accent' variant={'solid'} {...props} ref={forwardedRef}>
        {children}
      </Button>
    );
  },
);
