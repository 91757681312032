import { FEATURE_INTERNAL_TOOLS } from '@playful/api';
import { ProjectInfo, useCallbackRef } from '@playful/runtime';
import { createGenericContext } from '@playful/utils/createGenericContext';
import { ProjectStore } from '@playful/workbench/project/projectStorage';
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { useUserContext } from './user/UserContext';

export type ProjectStoreCtx = {
  projectStore: ProjectStore;
  projectInfos: ProjectInfo[];
  isLoading: boolean;
  setProjectInfos: (projectInfos: ProjectInfo[]) => void;
};

export type ProjectStoreProviderProps = {
  userId: string;
  onProjectInfos?(projectInfos: ProjectInfo[]): ProjectInfo[];
  onLoading?: (isLoading: boolean) => void;
  onCreateProjectStore?: (projectStore: ProjectStore) => void;
};

export const [useProjectStore, GenericProvider] = createGenericContext<ProjectStoreCtx>();
export const ProjectStoreGenericProvider = GenericProvider;

export function ProjectStoreProvider({
  userId,
  onProjectInfos,
  onLoading,
  children,
}: PropsWithChildren<ProjectStoreProviderProps>) {
  const [projectStore, setProjectStore] = useState<ProjectStore | undefined>();
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const onLoadingCb = useCallbackRef(onLoading);
  const onProjectInfosCb = useCallbackRef(onProjectInfos);
  const { hasFlag } = useUserContext();
  const initialState = useRef(false);

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      setIsLoading(isLoading);
      onLoadingCb?.(isLoading);
    },
    [onLoadingCb],
  );

  useEffect(() => {
    handleLoading(true);
    const store = new ProjectStore(userId, (projectInfos) => {
      let modifiedProjectInfos = onProjectInfosCb?.(projectInfos) ?? projectInfos;

      // Hide internal projects from external users.
      if (!hasFlag(FEATURE_INTERNAL_TOOLS)) {
        modifiedProjectInfos = modifiedProjectInfos.filter(
          (info) => !info.tags || !info.tags['_internal'],
        );
      }

      setProjectInfos(modifiedProjectInfos);

      if (!initialState.current) {
        // This callback is fired immediately on initialization.
        // Loading is done the second time this callback is fired.
        initialState.current = true;
      } else {
        handleLoading(false);
      }
    });

    setProjectStore(store);

    return () => store && store.close();
  }, [userId, onProjectInfosCb, handleLoading, hasFlag]);

  if (!projectStore) return null;

  return (
    <GenericProvider value={{ projectStore, projectInfos, isLoading, setProjectInfos }}>
      {children}
    </GenericProvider>
  );
}
