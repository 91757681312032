import '@fontsource/noto-sans/index.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/700.css';
import 'focus-visible/dist/focus-visible';

import './App.css';

import { ChakraProvider } from '@chakra-ui/react';
import { CssBaseline } from '@material-ui/core';
import { Theme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { defaultTheme } from '@playful/design_system/muiTheme';
import { theme as chakraTheme } from '@playful/design_system/theme';
import type { ProjectInfo } from '@playful/runtime';
import { buildPath, buildUserRoute } from '@playful/utils';
import { AppToasts } from '@playful/workbench/components/AppToasts';
import { loadGlobalState } from '@playful/workbench/globalState';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import { AppCommander, AppProvider } from './AppContext';
import { AppRoutes } from './AppRoutes';
import { TutorialExperience } from './components/TutorialExperience';
import { useMobileScrollHacks } from './hooks/useMobileScrollHacks';
import { Loading, LoadingProvider } from './Loading';
import { ChooseTemplate } from './TemplateChooser';
import { ThumbnailService } from './thumbnailService';
import { UserProvider, useUserContext } from './user/UserContext';

export const appName = 'Play';

loadGlobalState();

// Export the un-Wouter-monkey-patched history.pushState.
declare global {
  interface Window {
    originalPushState: any;
  }
}

export const pushHistoryState = window.originalPushState?.bind(window.history);

export const thumbnailService = new ThumbnailService();

const AppSnacks = lazy(
  () => import('@playful/workbench/components/AppSnacks' /* webpackChunkName: "AppSnacks" */),
);

const bodyBackgroundColor = '#ffffff';

const useStyles = makeStyles({
  appContainer: {
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    overflow: 'hidden',
    height: '100%',
  },
});

// Apply providers to the entire app.
export function App() {
  const [theme, setTheme] = useState(defaultTheme);

  // Apply the theme color to the document body so the iPhone status area
  // takes on that color when Play is launched from the home screen (PWA).
  useEffect(() => {
    document.body.style.backgroundColor = bodyBackgroundColor;
  }, [theme.palette.primary.main]);

  return (
    <LoadingProvider>
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <CssBaseline />
            <AppContent setTheme={setTheme} />
          </UserProvider>
        </ThemeProvider>
      </ChakraProvider>
    </LoadingProvider>
  );
}

export const AppContent: React.FC<{ setTheme: (theme: Theme) => void }> = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const [chooseTemplateOpen, setChooseTemplateOpen] = useState(false);
  const [, setLocation] = useLocation();

  const { ignoreScroll } = useMobileScrollHacks();

  const { id: userId } = user;

  const app: AppCommander = {
    newProject(): void {
      setChooseTemplateOpen(true);
    },
  };

  return (
    <AppProvider app={app}>
      <thumbnailService.RendererPool />

      <TutorialExperience />

      <div className={classes.appContainer} onScroll={ignoreScroll}>
        <Suspense fallback={<Loading />}>
          {userId && <AppRoutes />}
          <AppSnacks />
          <AppToasts />
        </Suspense>
        {chooseTemplateOpen && <ChooseTemplate onDone={onChooseTemplateDone} />}
      </div>
    </AppProvider>
  );

  function onChooseTemplateDone(projectInfo?: ProjectInfo, title?: string): void {
    setChooseTemplateOpen(false);

    if (projectInfo) {
      // TODO: my own setLocation that accepts state?
      setLocation(
        buildPath(buildUserRoute(projectInfo.ownerName, projectInfo.slug, 'edit'), {
          fork: '',
          title: encodeURIComponent(title!),
        }),
      );
    }
  }
};
