import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import lottieShare from '../../assets/5-share.lottie.json';
import { useTutorialHeaderStyles } from '../../useTutorialStyles';

export const Share: React.FC<{ color: string }> = (props) => {
  const classes = useTutorialHeaderStyles();
  return (
    <div style={{ backgroundColor: props.color, height: 143, width: '100%' }}>
      <Player id={'share-lottie'} autoplay loop src={lottieShare} className={classes.sharePlayer} />
    </div>
  );
};
