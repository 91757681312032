import {
  Box,
  ToastPositionWithLogical,
  createStandaloneToast,
  theme,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { ref, useSnapshot } from 'valtio';

import { snackState } from './AppSnacks';

type AppToastStatus = 'info' | 'warning' | 'success' | 'error';

export type AppToast = {
  id: string;
  description: React.ReactNode;
  status?: AppToastStatus;
  position?: ToastPositionWithLogical;
  duration?: number | null;
};

export const addToast = (toast: AppToast) => {
  const exists = snackState.toasts.find((t: AppToast) => t.id === toast.id);
  if (exists) return;

  snackState.toasts.push(
    ref({
      id: toast.id,
      description: toast.description,
      status: toast.status ?? 'info',
      position: toast.position ?? 'bottom',
      // Duration is in ms; null means toast will remain indefinitely until dismissed by removeToast()
      duration: toast.duration ?? null,
    }),
  );
};

export const removeToast = (toastId: string) => {
  snackState.toasts = snackState.toasts.filter((t: AppToast) => t.id !== toastId);
  const toast = createStandaloneToast();
  toast.close(toastId);
};

export function AppToasts() {
  const { toasts } = useSnapshot(snackState);
  const toast = useToast();
  useEffect(() => {
    toasts.map((t) => {
      const customToast = () => {
        return (
          <Box
            backgroundColor={theme.colors.gray[500]}
            color={theme.colors.white}
            fontSize={theme.fontSizes.xs}
            fontWeight={theme.fontWeights.bold}
            borderRadius={theme.radii.full}
            px={theme.space[3]}
            py={theme.space[2]}
            marginBottom={theme.space[8]}
            textAlign='center'
            boxShadow={theme.shadows.md}
          >
            {t.description}
          </Box>
        );
      };

      if (!toast.isActive(t.id)) {
        toast({
          id: t.id,
          description: t.description,
          duration: t.duration,
          position: t.position,
          render: customToast,
          containerStyle: {
            minWidth: theme.sizes[48],
          },
          onCloseComplete: () => {
            removeToast(t.id);
          },
        });
      }
    });
  }, [toast, toasts, toasts.length]);

  return null;
}
