import {
  User,
  UserFlags,
  updateUserFlags as updateUserFlagsApi,
  userFlagDefaults,
} from '@playful/api';
import { setUserProperties } from '@playful/api/telemetry/events/setUserProperties';
import { useCallback } from 'react';

import { useOptimisticState } from '../hooks/useOptimisticUpdate';
import { anonymousUser } from './anonymousUser';

const userFlagKeys = Object.keys(userFlagDefaults) as ReadonlyArray<keyof typeof userFlagDefaults>;
const INITIAL_FLAGS_KEY = 'initialUserFlags';

function extractValidFlags(flagsToValidate: string[]) {
  return flagsToValidate.reduce<string[]>((acc, flag) => {
    const validKey = userFlagKeys.find((f) => f.toLowerCase() === flag.toLowerCase());

    if (!validKey) return acc;

    acc.push(validKey);
    return acc;
  }, []);
}

function parseFlagsFromLocalStorage() {
  try {
    const parsedFlags = JSON.parse(localStorage.getItem(INITIAL_FLAGS_KEY) ?? '[]');
    return extractValidFlags(parsedFlags);
  } catch (e) {
    console.error(e);
    return [];
  }
}

function parseFlagsFromURL() {
  const initialUserParams = new URLSearchParams(window.location.search);

  // who knows what kind of mischief will end up going through here...
  try {
    const featureFlags = initialUserParams.get('features')?.split(',') ?? [];
    return extractValidFlags(featureFlags);
  } catch (e) {
    console.error('Error parsing flags from url');
    return [];
  }
}

// returns an array of user flag keys either from the address bar (preferred) or
// from localStorage (persisted)
export function parseInitialFlags() {
  const flagsFromUrl = parseFlagsFromURL();

  if (flagsFromUrl.length) return flagsFromUrl;

  return parseFlagsFromLocalStorage();
}

export function persistInitialFlags(flags: string[]) {
  window.localStorage.setItem(INITIAL_FLAGS_KEY, JSON.stringify(flags));
}

// loads the flags fresh each time
export function loadInitialFlags() {
  const initialValidFlags = parseInitialFlags();

  // return as a valid user flags object, all set to `true`
  return Object.fromEntries(initialValidFlags.map((flag) => [flag, true]));
}

export function resetInitialFlags() {
  window.localStorage.removeItem(INITIAL_FLAGS_KEY);
}

export function useFlags({ user }: { user: User }) {
  const [
    /** The most up-to-date user flags */
    userFlags,
    /**
     * Updates user flags optimistically: sets the user flag locally, update remotely,
     * and if successful, updates the up-to-date local flags with the response. If
     * there's an error, it will revert the local change.
     */
    updateOptimistically,
    {
      /** Updates the user flags on the server */
      makeRequest: updateFlags,
      /** Set the local user flag state (no server updates) */
      setValue: setUserFlags,
    },
  ] = useOptimisticState(async (updatedFlags: Partial<UserFlags>) => {
    return await updateUserFlagsApi(user.id, updatedFlags);
  }, userFlagDefaults);

  const updateUserFlags = useCallback(
    async (flags: Partial<UserFlags>) => {
      const updatedFlags = {
        ...userFlags,
        ...flags,
      };

      // `user` must be set for this to happen.
      // if you want to set flags during auth, do it in `onAuthStateChange`, not here!
      if (!user.id || user.id === anonymousUser.id) return;

      // first arg is the async update, second is the optimistic value
      const [error, finalUserFlags] = await updateOptimistically(
        () => updateFlags(updatedFlags),
        updatedFlags,
      );

      if (error) return;

      setUserProperties(user.id, {
        username: user.name,
        email: user.email!,
        visitor_type: user.userType || 'user',
        ...finalUserFlags,
      });
    },
    [user, userFlags, updateFlags, updateOptimistically],
  );

  const hasFlag = useCallback((flagName: keyof UserFlags) => !!userFlags?.[flagName], [userFlags]);

  /** Toggles a user flag */
  const toggleUserFlag = useCallback(
    (flagName: keyof UserFlags) => {
      updateUserFlags({
        [flagName]: !hasFlag(flagName),
      });
    },
    [hasFlag, updateUserFlags],
  );

  return {
    /** The user flags */
    userFlags,
    updateUserFlags,
    setUserFlags,
    hasFlag,
    toggleUserFlag,
  };
}
