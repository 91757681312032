import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  SvgIcon,
  SvgIconProps,
  WithStyles,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import type { StepProps } from '@material-ui/core/Step/Step';
import type { StepperProps } from '@material-ui/core/Stepper/Stepper';
import { Theme, withStyles } from '@material-ui/core/styles';
import type { defaultTheme } from '@playful/design_system/muiTheme';
import React from 'react';

const dialogStepperStyles = (theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      padding: 0,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  });

export interface DialogStepperProps extends WithStyles<typeof dialogStepperStyles> {
  children: React.ReactNode;
}
const DialogStepperUnstyled = (props: DialogStepperProps & StepperProps) => {
  const { children, ...rest } = props;
  return (
    <Stepper alternativeLabel nonLinear {...rest}>
      {children}
    </Stepper>
  );
};
export const DialogStepper = withStyles(dialogStepperStyles)(DialogStepperUnstyled);

export interface DialogStepProps {
  color: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useDialogStepStyles = makeStyles<typeof defaultTheme, DialogStepProps & StepProps>(
  (theme) => ({
    step: {
      padding: 0,
      paddingRight: theme.spacing(1),
    },
    button: {
      padding: 0,
      margin: 0,
      height: 12,
      width: 12,
    },
    label: {
      color: (props) => (props.active ? props.color : theme.palette.action.disabled),
    },
  }),
);

const DialogStepUnstyled = (props: DialogStepProps & StepProps) => {
  const classes = useDialogStepStyles(props);
  const { onClick, active, color, ...rest } = props;
  return (
    <Step {...rest} active={active} classes={{ root: classes.step }}>
      <StepButton onClick={onClick} classes={{ root: classes.button }}>
        <StepLabel
          classes={{
            root: classes.label,
          }}
          StepIconComponent={NoTextStepIcon}
          StepIconProps={{ color: 'inherit' }}
        />
      </StepButton>
    </Step>
  );
};

export const DialogStep = withStyles(() => ({}))(DialogStepUnstyled);

const NoTextStepIcon = ({ color }: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 12 12' fontSize='inherit' color={color}>
      <rect width='12' height='12' rx='6' />
    </SvgIcon>
  );
};
