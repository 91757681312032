import { Divider, Menu, MenuItem } from '@material-ui/core';
import { customEvent } from '@playful/api/telemetry/events/customEvent';
import { IconButton } from '@playful/design_system/Button';
import { HelpIcon } from '@playful/design_system/Icon';
import { Tooltip } from '@playful/design_system/Tooltip';
import { isSmallScreen } from '@playful/design_system/utils';
import { withNull } from '@playful/playkit/utils';
import React, { useState } from 'react';

import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { docPathForLink } from '../learningLinks';
import { useUserContext } from '../user/UserContext';

export type HelpMenuProps = {
  disabled?: boolean;
  onSettingsClick?(): void;
  inProject?: boolean;
};

const items: [string, string?, boolean?][] = [
  ['Learning Center', docPathForLink('docs/how-to-play')],
  ['Give Feedback', 'intercom:24403808'],
  ['Report a Bug', 'intercom:24424015'],
  ['Request a Feature', 'intercom:24423952'],
  [''],
  ['Get Started Tutorial', '/@gallery/9d46d3e7-d45a-d4ce-9d2c-73004201ba52', true],
  ['Add Interactivity', '/@gallery/0c5891de-586a-4143-9832-630df79b217f', true],
  ['Animate Anything', '/@gallery/906d6423-eefb-4d3d-b49e-de1d03013559', true],
  ['Make a Component', '/@hillary/1adb6b3f-e2b4-52d7-8a2f-8d8155feabf7', true],
  ['', undefined, true],
  ['Discord', 'https://discord.gg/playfulsoftware'],
  ['Twitter', 'https://twitter.com/PlayfulSoftware'],
  ['Instagram', 'https://www.instagram.com/playful_software/'],
];

export function HelpMenu(props: HelpMenuProps) {
  const { disabled, onSettingsClick, inProject = false } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasFlag } = useUserContext();
  const handleClose = withNull(setAnchorEl);

  function onButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onSelected(name: string, url: string): void {
    handleClose();
    customEvent(`Help ${name}`);

    if (!url) {
      window.alert('Coming soon!');
      return;
    }

    if (url.startsWith('intercom:')) {
      window.Intercom?.('startSurvey', url.split(':')[1]);
      return;
    }

    window.open(url, '_blank');
  }

  return (
    <>
      <ConditionalWrapper
        condition={!isSmallScreen()}
        wrapper={(children) => (
          <Tooltip shouldWrapChildren label='Help'>
            {children}
          </Tooltip>
        )}
      >
        <IconButton
          disabled={disabled}
          aria-label='Help Menu'
          aria-controls='help-menu'
          aria-haspopup={true}
          variant='ghost'
          isRound={true}
          onClick={onButtonClick}
          colorScheme='whiteAlpha'
          color={'gray.500'}
          icon={<HelpIcon />}
        />
      </ConditionalWrapper>
      <Menu id='help-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* Settings only displayed in help menu for mobile and within a project */}
        {isSmallScreen() && inProject && (
          // Hack: probably hacky; the Material UI Menu doesn't like fragments so this is wrapped in a div
          <div>
            <MenuItem dense onClick={onSettingsClick}>
              Project settings
            </MenuItem>
            <Divider />
          </div>
        )}

        {items
          .filter(([name, url, underConstruction]) =>
            underConstruction ? hasFlag('underConstruction') : true,
          )
          .map(([name, url], index) =>
            name ? (
              <MenuItem key={name} dense onClick={() => onSelected(name, url!)}>
                {name}
              </MenuItem>
            ) : (
              <Divider key={index} />
            ),
          )}
      </Menu>
    </>
  );
}
