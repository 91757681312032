import { Backdrop, BackdropProps } from '@material-ui/core';
import { useIsSmallScreen } from '@playful/design_system/utils';
import React, { FunctionComponent, RefObject } from 'react';

import type { TutorialState, TutorialStep } from './types';
import { useTutorialBlockerStyles } from './useTutorialStyles';

export type BlockerProps = BackdropProps & {
  fixedPosition?: boolean;
  tutorial?: TutorialState<any>;
};

const Callout: FunctionComponent<{
  callout: RefObject<any>;
  step: TutorialStep<any>;
}> = ({ callout, step }) => {
  const { mobileInset, desktopInset, selector } = step.calloutOptions ?? {};

  const isSmallScreen = useIsSmallScreen();

  const inset = isSmallScreen ? mobileInset : desktopInset;

  const xInset = !inset ? 0 : typeof inset === 'number' ? inset : inset.x;
  const yInset = !inset ? 0 : typeof inset === 'number' ? inset : inset.y;

  let target: Element | null = (callout as RefObject<any>)?.current as HTMLElement;

  if (target && selector) {
    target = target.querySelector(selector);
    if (!target) {
      // todo "${calloutName} > ${selector} join',' not found on ${step}" message
      throw new Error('Callout not found');
    }
  }

  const bounds = target.getBoundingClientRect();

  if (!bounds) {
    return null;
  }

  let { x, y, width, height } = bounds;

  x += xInset;
  y += yInset;
  width -= 2 * xInset;
  height -= 2 * yInset;

  const [min, max] = [width, height].sort();
  const radius = (min + (max - min) / 4) / 2;

  return (
    <circle cx={x + width / 2} cy={y + height / 2} fill='black' filter='url(#blur)' r={radius} />
  );
};

export const Blocker: FunctionComponent<BlockerProps> = (props) => {
  const { fixedPosition, tutorial, ...rest } = props;
  const classes = useTutorialBlockerStyles({
    fixedPosition: !!fixedPosition,
  });

  if (!tutorial) {
    return null;
  }

  const { activeStepIndex, callouts, steps } = tutorial;

  const step = steps[activeStepIndex];

  const callout = callouts[step.calloutOptions?.name];

  const className = [rest.className, classes.backdrop].filter(Boolean).join(' ');

  return (
    <Backdrop invisible {...rest} className={className}>
      <svg className={classes.svg}>
        <defs>
          <filter id='blur'>
            <feGaussianBlur in='SourceGraphic' stdDeviation='1' />
          </filter>

          <mask id='callouts'>
            <rect className={classes.maskCallout} />

            {callout && callout.current && <Callout callout={callout} step={step} />}
          </mask>
        </defs>
        <rect
          className={classes.background}
          height={window.innerHeight}
          width={window.innerWidth}
          mask='url(#callouts)'
        />
      </svg>
    </Backdrop>
  );
};
