import { ThemeOptions, createTheme } from '@material-ui/core/styles';

import { breakpoints } from './theme/foundations';

const themeOptions: ThemeOptions = {
  palette: {
    // Used by Switch, Slider, Input
    primary: { light: '#f6a5c0', main: '#c5c5c5', dark: '#aa647b' },

    secondary: { light: '#f6a5c0', main: '#f48fb1', dark: '#aa647b' },
    error: { light: '#e57373', main: '#f44336', dark: '#d32f2f' },
    warning: { light: '#ffb74d', main: '#ff9800', dark: '#f57c00' },
    info: { light: '#64b5f6', main: '#2196f3', dark: '#1976d2' },
    success: { light: '#81c784', main: '#4caf50', dark: '#388e3c' },
    text: {
      hint: 'rgb(101, 101, 101)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(210, 214, 220, 1)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    background: { default: 'rgb(240, 242, 243)', paper: 'rgb(252, 252, 253)' },
    divider: 'rgba(224, 228, 232)', // Also used as DesignerContainer backgroundColor.
    tonalOffset: 0.3,
  },

  overrides: {
    MuiButton: {
      // "default"
      contained: { border: '1px solid', borderRadius: '4px', backgroundColor: 'white' },
    },
    MuiAppBar: {
      colorPrimary: { backgroundColor: '#ffffff' },
    },
    MuiCssBaseline: {
      '@global': {
        '.disabled-gestures': {
          overscrollBehaviorX: 'contain',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
    MuiTabs: {
      scrollButtons: {
        width: '20px',
      },
    },
  },

  typography: {
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    body1: {
      fontSize: 14,
    },
    h5: {
      fontStyle: 'normal',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },

  breakpoints: {
    values: breakpoints,
  },

  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
};

export const defaultTheme = createTheme(themeOptions);
