import { SESSION_RECORDING_CONSENT } from '@playful/api';
import type { ProjectInfo } from '@playful/runtime';
import { buildUserRoute } from '@playful/utils';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Route, Router, Switch } from 'wouter';
import makeMatcher from 'wouter/matcher';

import { playDevTools } from './devtools';
import { ExplorerSubRoutes } from './explorer/ExplorerSubRoutes';
import { useMainLayout } from './layouts/MainLayout';
import { Loading } from './Loading';
import { ErrorPage, getErrorProps } from './pages/ErrorPage';
import { ProjectStoreProvider } from './ProjectStoreContext';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicOnlyRoute } from './PublicOnlyRoute';
import { Redirect } from './Redirect';
import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_GALLERY,
  ROUTE_HOME,
  ROUTE_MY_PROJECTS,
  ROUTE_REGISTER,
  ROUTE_SIGNIN,
} from './routes';
import { useUserContext } from './user/UserContext';
import { useRouteInterceptors } from './useRouteInterceptors';

const ProjectPlayerPage = lazy(
  () => import('./ProjectPlayerPage' /* webpackChunkName: "ProjectPlayerPage" */),
);
const WorkbenchPage = lazy(
  () => import('./pages/WorkbenchPage' /* webpackChunkName: "Workbench" */),
);
const UserPage = lazy(() => import('./pages/UserPage' /* webpackChunkName: "UserPage" */));

const SignInDialog = lazy(
  () => import('./user/AuthFlow/SignInDialog' /* webpackChunkName: "SignInDialog" */),
);
const SignUpDialog = lazy(
  () => import('./user/AuthFlow/SignUpDialog' /* webpackChunkName: "SignUpDialog" */),
);
const ChooseUsernameDialog = lazy(
  () =>
    import('./user/AuthFlow/ChooseUsernameDialog' /* webpackChunkName: "ChooseUsernameDialog" */),
);
const RecordingConsentDialog = lazy(
  () => import('./RecordingConsentDialog' /* webpackChunkName: "RecordingConsentDialog" */),
);
const ForgotPasswordDialog = lazy(
  () =>
    import('./user/AuthFlow/ForgotPasswordDialog' /* webpackChunkName: "ForgotPasswordDialog" */),
);

const defaultMatcher = makeMatcher();

/*
 * A custom routing matcher function that supports multipath routes
 */
const multipathMatcher = (patterns: string, path: string) => {
  for (const pattern of [patterns].flat()) {
    const [match, params] = defaultMatcher(pattern, path);
    if (match) return [true, params];
  }

  return [false, null];
};

export function AppRoutes() {
  const { isLoggedIn, user, userFlags } = useUserContext();

  const [signinTimeout, setSigninTimeout] = useState(false);
  // ProjectInfos are kept at the App level so they don't have to be reloaded e.g. as ProjectExplorer
  // comes and goes.
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);

  const handleProjectInfos = (projectInfos: ProjectInfo[]) => {
    setProjectInfos(projectInfos);
    return projectInfos;
  };

  // Avoid displaying the signed out experience for the very brief interval while
  // firebase is signing the user in via cookie. Unsightly flashing.
  useEffect(() => {
    window.setTimeout(() => setSigninTimeout(true), 250);
  }, []);

  // Make the list of ProjectInfos available to devtools.
  useEffect(() => {
    playDevTools.projectInfos = projectInfos;
  }, [projectInfos]);

  const projEditRoute = buildUserRoute(':userName', ':slug', 'edit');
  const projRoute = buildUserRoute(':userName', ':slug');
  const userRoute = buildUserRoute(':userName');
  const portalRef = useRef(null);
  const { Header } = useMainLayout();
  const headerRenderFn = (getChildren: () => React.ReactNode) =>
    portalRef.current ? createPortal(getChildren(), portalRef.current) : null;
  const { isRedirecting } = useRouteInterceptors();

  const { [SESSION_RECORDING_CONSENT]: sessionRecordingConsent } = userFlags ?? {};
  const shouldPromptForConsent = isLoggedIn && !sessionRecordingConsent;
  const shouldChooseUsername = isLoggedIn && !user.name;

  if (isRedirecting || !signinTimeout) {
    return <Loading />;
  }

  if (shouldChooseUsername)
    return (
      <Suspense fallback={<Loading />}>
        <Header />
        <ChooseUsernameDialog />
      </Suspense>
    );

  return (
    <ProjectStoreProvider userId={user.id} onProjectInfos={handleProjectInfos}>
      <Header portalRef={portalRef} />
      <Suspense fallback={<Loading />}>
        {shouldPromptForConsent && <RecordingConsentDialog />}
        <Router matcher={multipathMatcher as any}>
          <Switch>
            <ProtectedRoute authorized={isLoggedIn} path={projEditRoute}>
              {(params) => (
                <WorkbenchPage
                  portalToHeader={headerRenderFn}
                  userName={params.userName}
                  slug={params.slug}
                />
              )}
            </ProtectedRoute>
            <Route path={projRoute}>
              {(params) => (
                <ProjectPlayerPage
                  portalToHeader={headerRenderFn}
                  userName={params.userName}
                  slug={params.slug}
                />
              )}
            </Route>
            <Route path={userRoute}>
              {(params) =>
                params.userName && params.userName !== 'anonymous' ? (
                  <UserPage portalToHeader={headerRenderFn} userName={params.userName} />
                ) : (
                  <Redirect to={ROUTE_HOME} query={window.location.search} />
                )
              }
            </Route>

            <PublicOnlyRoute authorized={isLoggedIn} path={ROUTE_SIGNIN}>
              <SignInDialog />
            </PublicOnlyRoute>
            <PublicOnlyRoute authorized={isLoggedIn} path={ROUTE_REGISTER}>
              <SignUpDialog />
            </PublicOnlyRoute>
            <PublicOnlyRoute authorized={isLoggedIn} path={ROUTE_FORGOT_PASSWORD}>
              <ForgotPasswordDialog />
            </PublicOnlyRoute>

            {/* Gallery should be accessible to non-logged in peeps */}
            <Route path={ROUTE_GALLERY}>
              <ExplorerSubRoutes portalToHeader={headerRenderFn} />
            </Route>

            {/* the root path gets redirected to depending on auth status, so leave explicit */}
            <Route path={[ROUTE_HOME, ROUTE_MY_PROJECTS] as any}>
              <ExplorerSubRoutes portalToHeader={headerRenderFn} />
            </Route>

            {/* fallbacks, which take auth status into account */}
            <Route>
              <ErrorPage portalToHeader={headerRenderFn} {...getErrorProps(404)} />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ProjectStoreProvider>
  );
}
