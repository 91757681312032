import {
  InitialUserClaims,
  PublicUser,
  User,
  UserClaims,
  UserFlags,
  getUser,
  getUserFlags,
  publicUserFromUser,
  updateUser,
  userFlagDefaults,
} from '@playful/api';
import { getAuth } from '@playful/api/firebase';
import { identify } from '@playful/api/telemetry/events/identify';
import { shareSession } from '@playful/api/telemetry/events/shareSession';
import { Status, fromPromise } from '@playful/utils';
import {
  AuthProvider,
  User as FBUser,
  ParsedToken,
  signOut as fbSignOut,
  getIdTokenResult,
  onAuthStateChanged,
  onIdTokenChanged,
} from 'firebase/auth';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocalStorageState } from '../hooks/handyHooks';
import { useLoader } from '../workbench/useLoader';
import { anonymousUser } from './anonymousUser';
import {
  checkRedirectResult,
  authenticateWithFederatedProvider as federatedAuth,
} from './authenticateWithFederatedProvider';
import { initialUser } from './initialUser';
import { SignInSchema, signIn } from './signIn';
import { SignUpSchema, signUp } from './signUp';
import {
  parseInitialFlags,
  persistInitialFlags,
  resetInitialFlags,
  useFlags,
} from './useUserFlags';

export const getLoginStatus = (user: User) => user?.id !== 'anonymous';
export const getAdminStatus = (loggedIn: boolean, admin: boolean) => loggedIn && admin;

export type UserCtx = {
  user: User;
  isProcessing: boolean;
  publicUsers: { [userId: string]: PublicUser };
  userFlags: UserFlags;
  setUserFlags: Dispatch<SetStateAction<UserFlags>>;
  updateCurrentUser: (user: User | undefined) => void;
  hasFlag: (flagName: keyof UserFlags) => boolean;
  hasClaim: (claimName: keyof UserClaims) => boolean;
  toggleUserFlag: (flagName: keyof UserFlags) => void;
  updateUserFlags: (flags: Partial<UserFlags>) => void;
  getPublicUser: (userId: string) => Promise<PublicUser>;
  updatePublicUser: (publicUser: PublicUser) => Promise<void>;
  authenticateWithEmail: <T extends boolean>(
    values: T extends true ? SignUpSchema : SignInSchema,
    isNewUser: T,
  ) => Promise<Status<void>>;
  authenticateWithFederatedProvider: (
    provider: AuthProvider,
    isNewUser: boolean,
    redirect?: boolean,
  ) => Promise<Status<void>>;
  isLoggedIn: boolean;
  isAdmin: boolean;
  signOut: () => void;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  previousUserEmail: string | null;
};

// this is a hack for now.
// later, we'll need to go through and just add assertions and null checks for these across the codebase.
// this is safe, for now, as we don't render the app at all if `user` isn't true, but that may not
// always be the case.
export type UseUserCtx = Omit<UserCtx, 'user'> & {
  user: User;
};

export const UserContext = createContext<UserCtx>(undefined as any);

export const useUserContext = () => useContext(UserContext);

export type UserProviderProps = {
  children: ReactNode;
};

// expose pre-filled provider
export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User>(initialUser);
  const [publicUsers, setPublicUsers] = useState<{ [userId: string]: PublicUser }>({});
  const [userClaims, setUserClaims] = useState<UserClaims>(InitialUserClaims);
  const [previousUserEmail, setPreviousUserEmail] = useLocalStorageState(null, 'previousUserEmail');
  const { userFlags, updateUserFlags, setUserFlags, hasFlag, toggleUserFlag } = useFlags({ user });
  const updateCurrentUser = useCallback<UserCtx['updateCurrentUser']>((user) => {
    if (user) {
      setPublicUsers((publicUsers) => ({
        ...publicUsers,
        [user.id]: publicUserFromUser(user),
      }));
    }
  }, []);

  const hasClaim = useCallback<UserCtx['hasClaim']>(
    (claimName) => userClaims?.[claimName],
    [userClaims],
  );

  const isLoggedIn = getLoginStatus(user);
  const isAdmin = getAdminStatus(isLoggedIn, hasClaim('admin'));

  const updateClaims = useCallback((claims: ParsedToken) => {
    setUserClaims((userClaims) => ({
      ...userClaims,
      admin: !!claims.admin ?? InitialUserClaims.admin,
    }));
  }, []);

  const clearClaims = useCallback(() => {
    setUserClaims({ ...InitialUserClaims });
  }, [setUserClaims]);

  // if not logged in, load the initial flags from the url (or fallback to localStorage) asap, as we want
  // to persist this before we potentially lose the state
  useEffect(() => {
    if (isLoggedIn) return;

    const flags = parseInitialFlags();
    if (flags.length) persistInitialFlags(flags);
  }, [isLoggedIn]);

  const signOut = useCallback<UserCtx['signOut']>(async () => {
    fbSignOut(getAuth());
    setUser(anonymousUser); // Do this here vs waiting for onAuthStateChanged so that subsquent navigation sees a signed out state
    setUserFlags(userFlagDefaults);
    clearClaims();
  }, [clearClaims, setUserFlags]);

  const getPublicUser = useCallback<UserCtx['getPublicUser']>(async (userId) => {
    const user = await getUser(userId);
    const publicUser = publicUserFromUser(user);
    // Update user cache.
    setPublicUsers((publicUsers) => ({ ...publicUsers, [publicUser.id]: publicUser }));
    return user;
  }, []);

  const updatePublicUser = useCallback<UserCtx['updatePublicUser']>(async (publicUser) => {
    const user = await updateUser(publicUser.id, {
      // Pass null to remove data.
      profileProject: publicUser.profileProject,
    });

    const updatedPublicUser = publicUserFromUser(user);

    // Update public users cache.
    setPublicUsers((publicUsers) => ({
      ...publicUsers,
      [updatedPublicUser.id]: updatedPublicUser,
    }));
  }, []);

  const memoValues = useMemo(
    () => ({
      publicUsers,
      user,
      userFlags,
    }),
    [user, userFlags, publicUsers],
  );

  const { isLoading: isAuthPending, makeRequest: handleAuth } = useLoader(
    useCallback(
      async (p) => {
        const [err] = await fromPromise(p);

        // here's where we can clean up the user if there's an error during authentication.
        if (err) await signOut();

        return p;
      },
      [signOut],
    ),
  );

  const _processAuth = useCallback(
    async (firebaseUser: FBUser | null) => {
      if (isAuthPending) return;

      if (!firebaseUser) {
        setUser(anonymousUser);
        identify();
        return;
      }

      let user = initialUser;
      try {
        user = await getUser(firebaseUser.uid);
      } catch (e) {
        // ignore the error if there is one...on initial registration, the user might not
        // exist yet
      }
      const updatedUser: User = {
        ...user,
        id: firebaseUser.uid,
        email: firebaseUser.email!,
      };

      const flags = await getUserFlags(updatedUser.id);
      setUserFlags(flags);
      resetInitialFlags();
      setUser(updatedUser);

      identify(updatedUser.id, {
        username: updatedUser.name,
        email: updatedUser.email || '',
        visitor_type: updatedUser.userType || 'user',
      });

      shareSession();
      setPreviousUserEmail(user?.email);
    },
    [isAuthPending, setPreviousUserEmail, setUserFlags],
  );

  const { isLoading: isProcessingAuth, makeRequest: processAuth } = useLoader(_processAuth);

  const { isLoading: isCheckingRedirectResult, makeRequest: checkForRedirect } = useLoader(
    useCallback(async () => {
      const creds = await checkRedirectResult();

      // on a redirected authentication, this happens before onAuthStateChanged!
      // let's not wait for that then...
      if (creds?.user) await processAuth(creds.user);
    }, [processAuth]),
  );

  // See if we have any auth results we need to take care of from a returned
  // redirect via federated authentication
  useEffect(() => {
    checkForRedirect();
  }, [checkForRedirect]);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), processAuth);
  }, [processAuth]);

  // Update claims when the token updates
  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (firebaseUser) => {
      if (!firebaseUser) {
        clearClaims();
        return;
      }

      const tokenResult = await getIdTokenResult(firebaseUser);
      if (tokenResult) {
        updateClaims(tokenResult.claims);
      } else {
        clearClaims();
      }
    });
  }, [updateClaims, clearClaims]);

  const authenticateWithEmail = useCallback<UserCtx['authenticateWithEmail']>(
    (values, isNewUser) => {
      const authMethod = isNewUser ? signUp : signIn;
      return handleAuth(authMethod(values));
    },
    [handleAuth],
  );

  const authenticateWithFederatedProvider = useCallback<
    UserCtx['authenticateWithFederatedProvider']
  >(
    (federatedProvider, isNewUser, redirect) => {
      return handleAuth(federatedAuth(federatedProvider, isNewUser, !!redirect));
    },
    [handleAuth],
  );

  return (
    <UserContext.Provider
      value={{
        ...memoValues,
        authenticateWithEmail,
        authenticateWithFederatedProvider,
        setUserFlags,
        updateUserFlags,
        toggleUserFlag,
        isAdmin,
        isLoggedIn,
        updatePublicUser,
        getPublicUser,
        hasClaim,
        hasFlag,
        updateCurrentUser,
        signOut,
        setUser,
        previousUserEmail,
        isProcessing: isCheckingRedirectResult || isProcessingAuth,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
