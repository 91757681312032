import LogRocket from 'logrocket';

// Sends a custom event to various services
export function customEvent(name: string, props?: Record<string, any>) {
  try {
    const { heap, gtag, rudderanalytics } = window;
    heap?.track(name, props ?? {});
    gtag?.('event', name, props);
    rudderanalytics?.track(name, props);
    LogRocket.track(name, props ?? {});
  } catch (e) {
    console.warn(`Error sending custom event: ${name}`, props);
  }
}
