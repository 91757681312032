import type { SnackbarOrigin } from '@material-ui/core';
import { IconButton, Snackbar } from '@material-ui/core';
import { amber, blue, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { SyntheticEvent } from 'react';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'break-word',
  },
}));

export interface CustomSnackbarProps {
  message?: React.ReactNode;
  message2?: React.ReactNode;
  onClose?: () => void;
  open: boolean;
  anchorOrigin?: SnackbarOrigin;
  variant?: keyof typeof variantIcon;
}

export function CustomSnackbar(props: CustomSnackbarProps) {
  const classes = useStyles();
  const {
    open,
    message,
    message2,
    onClose,
    variant = 'success',
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
  } = props;
  const Icon = variantIcon[variant];

  function handleClose(event?: SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    onClose?.();
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      ContentProps={{ className: classes[variant] }}
      autoHideDuration={6000}
      open={open}
      message={
        <div>
          <div id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </div>
          {message2}
        </div>
      }
      action={[
        <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      onClose={handleClose}
    />
  );
}
