import {
  DialogContent as MuiDialogContent,
  Typography,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';

export const dialogCardContentStyles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      '&> *': {
        paddingTop: theme.spacing(1.5),
      },
    },
  });

export interface DialogCardContentsProps extends WithStyles<typeof dialogCardContentStyles> {
  title?: string;
  children: React.ReactNode;
}

export const DialogCardContent = withStyles(dialogCardContentStyles)(
  (props: DialogCardContentsProps) => {
    const { children, title, ...rest } = props;
    return (
      <MuiDialogContent {...rest}>
        {title && <Typography variant='h5'>{title}</Typography>}
        {children}
      </MuiDialogContent>
    );
  },
);
