import React from 'react';
import { Route, RouteProps, useLocation } from 'wouter';

import { Redirect } from './Redirect';
import { useUserContext } from './user/UserContext';

export function ProtectedRoute({
  authorized,
  ...routeProps
}: RouteProps & { authorized?: boolean }) {
  const [location] = useLocation();
  const { previousUserEmail } = useUserContext();

  if (!authorized) {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.append('next', location);

    const newSearch = searchParams.toString();

    if (previousUserEmail) {
      return <Redirect replace={true} to='/signin' query={newSearch && `?${newSearch}`} />;
    }

    return <Redirect replace={true} to='/register' query={newSearch && `?${newSearch}`} />;
  }

  return <Route {...routeProps} />;
}
