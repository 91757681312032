import { TUTORIAL_ONBOARDING } from '@playful/api';

import { createTutorial } from '../createTutorial';
import { Elements } from './headers/Elements';
import { Share } from './headers/Share';
import { Style } from './headers/Style';
import { Welcome } from './headers/Welcome';

export const { useOnboardingTutorial, useOnboardingTutorialCallouts } = createTutorial({
  name: 'Onboarding',
  flag: TUTORIAL_ONBOARDING,
  steps: [
    {
      text: 'Play lets you design interactive creations by using our unique superpowers. Learn about them in just 30 seconds.',
      header: Welcome,
      title: 'Creative superpowers',
      color: '#5D84FD',
    },
    {
      text: 'Say hello to elements, your design superpowers. Express yourself with interactive content, confetti and (yes!) flying emojis.',
      header: Elements,
      title: 'Elements',
      color: '#A35DFD',
      calloutOptions: {
        name: 'elementsButton',
        desktopPlacement: 'right-start',
        mobileInset: -10,
      },
    },
    {
      text: 'Punch up your creation by styling your elements. Rotate objects, change color or opacity, and more.',
      header: Style,
      title: 'Style',
      color: '#C4ED6D',
      calloutOptions: {
        name: 'propertiesInspectorTabButton',
        desktopInset: -15,
        desktopPlacement: 'left-start',
        mobileInset: -17,
        selector: 'span',
      },
    },
    {
      text: 'Share the love! Set your friends (or the world!) loose on your creation.',
      header: Share,
      title: 'Share it!',
      color: '#5D84FD',
      calloutOptions: {
        name: 'shareButton',
        desktopPlacement: 'bottom-start',
        mobileInset: -17,
      },
    },
  ],
});
