import { User, apiRequest, axiosRequest } from '@playful/api';
import type { ProjectId, ProjectInfo, ProjectState, Tags, UserId } from '@playful/runtime';
import { ProjectPermissions, readRemoteProject, serialize } from '@playful/runtime';
import { slugify } from '@playful/utils/slugify';
import { DataSnapshot, DatabaseReference, getDatabase, off, onValue, ref } from 'firebase/database';

import { PROJECT_JSON, Resource } from './resources';

function suggestSuffix(
  baseName: string,
  takenNames: string[],
  alwaysSuffix = false,
  space = false,
  delim = ' ',
): string {
  let n = 1;

  while (true) {
    const candidateName = !alwaysSuffix ? baseName : baseName + (space ? delim : '') + n;
    if (!takenNames.includes(candidateName)) {
      return !alwaysSuffix ? '' : String(n);
    }
    alwaysSuffix = true;
    n++;
  }
}

// IMPORTANT: keep in sync with services.go
const everyone = 'everyone';

// Unsaved ProjectInfos lack a few things
type UnsavedProjectInfo = Omit<ProjectInfo, 'id' | 'created' | 'modified' | 'version'>;

export class ProjectStore {
  loaded = false;
  private readonly userId: UserId;
  private projectInfos: ProjectInfo[];
  private userPushRef: DatabaseReference;

  constructor(userId: UserId, private listener: (projectInfos: ProjectInfo[]) => void) {
    this.userId = userId;

    this.projectInfos = [];

    // Notify of initial state.
    this.notifyListener();

    if (userId === 'public') {
      // TODO: replace this with some sort of 'global push' notification
      this.userPushRef = ref(getDatabase(), `userPush/${everyone}/public_projects`);
    } else {
      this.userPushRef = ref(getDatabase(), `userPush/${userId}/user_projects`);
    }

    onValue(this.userPushRef, this.onProjectsUpdate);

    // Do a refresh here. Normally firebase will do it *unless* the userPushRef above doesn't exist
    this.refresh();
  }

  close() {
    off(this.userPushRef, 'value', this.onProjectsUpdate);
  }

  private getProjectInfo(id: ProjectId): ProjectInfo | undefined {
    const info = this.projectInfos.find((info) => info.id === id);
    return info && { ...info };
  }

  async getProjectBySlug(userName: string, slug: string): Promise<ProjectInfo | undefined> {
    const ret = await axiosRequest(`users/@${userName}/projects/${slug}`);

    if (ret.data) {
      this.updateProjectInfoCache(ret.data);
      return ret.data;
    }
  }

  async getProjectInfoAsync(
    projectId: ProjectId,
    options?: { bypassCache?: boolean },
  ): Promise<ProjectInfo | undefined> {
    const projectInfo = !options?.bypassCache && this.getProjectInfo(projectId);

    if (projectInfo) return projectInfo;

    const ret = await axiosRequest(`projects/${projectId}`);

    if (ret.data) {
      this.updateProjectInfoCache(ret.data);
      return ret.data;
    }
  }

  updateProjectInfoCache(info: ProjectInfo): void {
    const index = this.projectInfos.findIndex((infoT) => infoT.id === info.id);
    if (index !== -1) {
      this.projectInfos[index] = info;
      this.notifyListener();
    }
  }

  async readProject(info: ProjectInfo, projectResource?: Resource): Promise<ProjectState> {
    const res = projectResource ?? (await Resource.get(info.project));
    return readRemoteProject(res.getDataUrl(PROJECT_JSON));
  }

  // Writes the project state remotely and returns a ProjectInfo with new values.
  async writeProject(
    projectInfo: ProjectInfo,
    projectResource: Resource,
    state: ProjectState,
  ): Promise<ProjectInfo> {
    // Don't modify the original.
    projectInfo = { ...projectInfo };

    // Write the project's files to remote storage.
    const file = serialize(state);
    const buffer = new TextEncoder().encode(file);

    // Write the project's files to remote storage.
    await projectResource.uploadDataBuffer(PROJECT_JSON, buffer, 'application/json');

    // Write the project's info to the database.
    const writtenInfo = await this.writeProjectInfo(projectInfo);

    return writtenInfo;
  }

  async deleteProject(id: ProjectId): Promise<void> {
    const info = this.getProjectInfo(id);
    if (info) {
      await apiRequest(`projects/${info.id}`, {
        method: 'DELETE',
      });
    }
  }

  async duplicateProject(
    info: ProjectInfo,
    newOwner: UserId, // TODO These will always be the current user...
    newOwnerName: string,
    title?: string,
    template = false,
  ): Promise<{ info: ProjectInfo; state: ProjectState; res: Resource }> {
    const dupInfo: UnsavedProjectInfo = {
      owner: newOwner,
      ownerName: newOwnerName,
      title: info.title + ' copy',
      template: template ? info.id! : info.template,
      project: '',
      slug: '',
      permissions: {
        locked: false,
        showInGallery: false,
        allowRemixing: false,
        liveUpdate: false,
        showLogo: true,
      },
    };

    const state = await this.readProject(info);

    // Clear Components exported flags.
    if (state.Components) {
      Object.entries(state.Components).forEach(([key, component]) => {
        component._meta!.exported = false;
      });
    }

    // Duplicate the resource
    const res = await new Resource(info.project).clone();

    // Write the project's state.
    const file = serialize(state);
    const buffer = new TextEncoder().encode(file);
    const newProjectTitle = title ?? info.title;

    await res.uploadDataBuffer(PROJECT_JSON, buffer, 'application/json');

    dupInfo.project = res.id;

    if (template) {
      dupInfo.title = suggestTitle(newProjectTitle, this.projectInfos);
    } else {
      dupInfo.title = suggestTitle(dupInfo.title, this.projectInfos);
    }

    dupInfo.slug = suggestSlug(slugify(dupInfo.title), this.projectInfos);

    // Drop tags we don't want to automatically propagate to duplicated projects.
    if (info.tags) {
      dupInfo.tags = {};
    }

    // Write the project's info
    const savedInfo = await this.createProjectInfo(dupInfo);

    this.projectInfos.push(savedInfo);

    return {
      info: savedInfo,
      state,
      res,
    };
  }

  newProjectInfo(user: User, title: string): UnsavedProjectInfo {
    const titleSuggestion = suggestTitle(title, this.projectInfos);

    return {
      owner: user.id,
      ownerName: user.name,
      title: titleSuggestion,
      template: '',
      project: '',
      slug: suggestSlug(slugify(titleSuggestion), this.projectInfos),
      permissions: {
        locked: false,
        showInGallery: false,
        allowRemixing: false,
        liveUpdate: false,
        showLogo: true,
      },
    };
  }

  async updateProjectPermissions(info: ProjectInfo, perms: Partial<ProjectPermissions>) {
    const shouldRepublish = !info.published && (perms.allowRemixing || perms.showInGallery);

    const newInfo = await this.writeProjectInfo({
      ...info,
      permissions: { ...info.permissions, ...perms },
    });

    const publishedInfo = shouldRepublish ? await this.publish(newInfo.id) : newInfo;

    return publishedInfo || info;
  }

  async renameProject(info: ProjectInfo, title: string) {
    const newInfo = await this.writeProjectInfo({
      ...info,
      title,
    });

    return newInfo;
  }

  async publish(id: ProjectId): Promise<ProjectInfo | undefined> {
    const info = this.getProjectInfo(id);

    if (!info) {
      // TODO: enforce can only publish remotely saved projects
      return;
    }

    await apiRequest(`projects/${info.id}/publish`, {
      method: 'PUT',
    });

    return this.getProjectInfoAsync(id, { bypassCache: true });
  }

  async unpublish(id: ProjectId): Promise<ProjectInfo | undefined> {
    const info = this.getProjectInfo(id);

    // TODO: enforce can only unpublish remotely saved projects
    if (!info) return;

    await apiRequest(`projects/${info.id}/unpublish`, {
      method: 'PUT',
    });

    const updatedInfo = await this.getProjectInfoAsync(id, { bypassCache: true });

    if (!updatedInfo) return;

    return this.updateProjectPermissions(updatedInfo, { showInGallery: false });
  }

  // Update an existing project
  async writeProjectInfo(info: ProjectInfo): Promise<ProjectInfo> {
    const body = JSON.stringify(info);

    // Update an existing project
    const ret = await apiRequest(`projects/${info.id}`, {
      method: 'PUT',
      body,
    });
    return (await ret.json()) as ProjectInfo;
  }

  // Create a new project
  async createProjectInfo(info: UnsavedProjectInfo): Promise<ProjectInfo> {
    const body = JSON.stringify(info);

    // Create a new project
    const ret = await apiRequest(`projects`, {
      method: 'POST',
      body,
    });
    return (await ret.json()) as ProjectInfo;
  }

  async setProjectSlug(projectId: ProjectId, slug: string): Promise<ProjectInfo> {
    // Create a new project
    const ret = await apiRequest(`projects/${projectId}/slug`, {
      method: 'PUT',
      body: JSON.stringify({ slug }),
    });
    const info = (await ret.json()) as ProjectInfo;

    this.updateProjectInfoCache(info);

    return info;
  }

  // Fetch projects from the server
  private async refresh(): Promise<void> {
    const url = this.userId === 'public' ? 'projects' : `users/${this.userId}/projects`;
    const ret = await apiRequest(url, {
      method: 'GET',
    });
    this.projectInfos = await ret.json();
    this.notifyListener();
  }

  private notifyListener(): void {
    this.listener(this.projectInfos);
  }

  // Firebase has told us there's been a update to projects
  private onProjectsUpdate = (snapshot: DataSnapshot): void => {
    this.loaded = true;
    this.refresh();
  };
}

// Fetch public (shared with the community) project infos by tag
export async function getProjectInfosByTag(tag: string): Promise<ProjectInfo[]> {
  const ret = await apiRequest(`projects?tag=${encodeURIComponent(tag)}`, {
    method: 'GET',
  });
  return await ret.json();
}

function suggestTitle(title: string, projectInfos: ProjectInfo[]): string {
  const suffix = suggestSuffix(
    title,
    projectInfos.map((info) => info.title),
    false,
    true,
  );
  return title + (suffix ? ' ' + suffix : '');
}

function suggestSlug(slug: string, projectInfos: ProjectInfo[]): string {
  const suffix = suggestSuffix(
    slug,
    projectInfos.map((info) => info.slug),
    false,
    true,
    '-',
  );
  return slug + (suffix ? '-' + suffix : '');
}

export type ComponentEntry = {
  ownerId: UserId;
  projectId: ProjectId;
  projectTitle?: string;
  name: string;
  title?: string;
  description?: string;
  author?: string;
  public: boolean;
  tags?: Tags;
};

// Fetch components
export async function getComponents(): Promise<ComponentEntry[]> {
  const ret = await apiRequest('components', {
    method: 'GET',
  });
  return (await ret.json()) as ComponentEntry[];
}
