import { customEvent } from '@playful/api/telemetry/events/customEvent';
import { isSmallScreen } from '@playful/design_system/utils';
import type { ProjectId } from '@playful/runtime';
import { isObjectNotArray } from '@playful/runtime/util';
import { useCallback } from 'react';
import { proxy, snapshot, subscribe, useSnapshot } from 'valtio';

export const defaultPanelWidth = 326;
export const splitterSize = 1;
export const showProjectFilterButton = false;
export const defaultFontSize = 12;

const defaultContentTab = 'kits';
const defaultKitId = ''; // Can't be `undefined` because the key will be dropped by valtio.

export type InspectorTab = 'properties' | 'effects' | 'actions' | 'styles';

const initialProjectWorkbenchState: ProjectWorkbenchState = {
  expandedNodes: [],
  lastUpdated: new Date().getTime(),
};

const initialState = {
  workbench: {
    // Persistent
    showContentPanel: !isSmallScreen(),
    showInspector: true,

    hasSeenKits: false,
    inspectorPanelWidth: defaultPanelWidth,
    inspectorPanel: {
      activeTab: 'properties' as InspectorTab,
      expanded: false,
      mobileInspectorHeight: 80,
    },
    contentPanel: {
      activeTab: defaultContentTab as string | undefined,
      activeKitId: defaultKitId as ProjectId | undefined,
      flashCount: 0, // increments if the user attempts to open a content panel already open
    },
  },
  projectWorkbenchStateCollection: {} as Record<string, ProjectWorkbenchState>,
};

export type ProjectWorkbenchState = { expandedNodes: string[]; lastUpdated: number };

export const globalState = proxy(initialState);

// Persist the global state as it changes.
// TODO: Global state we don't want persisted.
subscribe(globalState, () => saveGlobalState());

export function loadGlobalState(): void {
  const json = localStorage.getItem('globalState');
  if (json) {
    const loadedState = JSON.parse(json);
    mergeObjects(globalState, loadedState);
    mergeProjectWorkbenchState(globalState, loadedState);

    // Make sure everyone sees the kits at least once.
    if (!globalState.workbench.hasSeenKits) {
      globalState.workbench.hasSeenKits = true;
      globalState.workbench.contentPanel.activeTab = defaultContentTab;
      globalState.workbench.contentPanel.activeKitId = defaultKitId;
    }
  }
}

export function saveGlobalState(): void {
  localStorage.setItem('globalState', JSON.stringify(globalState, null, 2));
}

// Preserves the projectWorkbenchState when a project is copied
export function cloneProjectWorkbenchState(oldId: ProjectId, newProjectId: ProjectId) {
  if (globalState.projectWorkbenchStateCollection[oldId]) {
    globalState.projectWorkbenchStateCollection[newProjectId] = {
      ...globalState.projectWorkbenchStateCollection[oldId],
    };
  }
}

// Recursively merge object b's properties on top of object a's.
function mergeObjects(a: any, b: any): void {
  for (const key in a) {
    if (key in b) {
      if (isObjectNotArray(a[key])) {
        mergeObjects(a[key], b[key]);
      } else {
        a[key] = b[key];
      }
    }
  }
}

// Merge the designState in a special-ish way to deal with the dynamic project indexing.
function mergeProjectWorkbenchState(a: any, b?: any): void {
  if (!b) {
    return;
  }
  for (const key in b.projectWorkbenchStateCollection) {
    if (!a.projectWorkbenchStateCollection[key]) {
      a.projectWorkbenchStateCollection[key] = initialProjectWorkbenchState;
    }

    for (const propertyKey in b.projectWorkbenchStateCollection[key]) {
      if (propertyKey in initialProjectWorkbenchState) {
        a.projectWorkbenchStateCollection[key][propertyKey] =
          b.projectWorkbenchStateCollection[key][propertyKey];
      }
    }
  }
}

// Given a project ID returns the projectDesignState and setter
export function useProjectWorkbenchState(
  projectId: ProjectId,
  initialState: Partial<ProjectWorkbenchState>,
): {
  projectWorkbenchState: ProjectWorkbenchState;
  setProjectWorkbenchState: (state: Partial<ProjectWorkbenchState>) => void;
} {
  const { projectWorkbenchStateCollection } = useSnapshot(globalState);
  if (!globalState.projectWorkbenchStateCollection[projectId]) {
    globalState.projectWorkbenchStateCollection[projectId] = {
      ...initialProjectWorkbenchState,
      ...initialState,
    };
  }

  const setProjectWorkbenchState = useCallback(
    (state: Partial<ProjectWorkbenchState>) => {
      globalState.projectWorkbenchStateCollection[projectId] = {
        ...globalState.projectWorkbenchStateCollection[projectId],
        ...state,
        lastUpdated: new Date().getTime(),
      };
    },
    [projectId],
  );

  return {
    projectWorkbenchState:
      projectWorkbenchStateCollection[projectId] ||
      globalState.projectWorkbenchStateCollection[projectId] ||
      initialState,
    setProjectWorkbenchState,
  };
}

export function setActiveInspectorTab(tab: InspectorTab) {
  if (!!tab) {
    globalState.workbench.inspectorPanel.activeTab = tab;
  }
}

export function toggleInspector(show: boolean) {
  const { showInspector } = snapshot(globalState.workbench);
  if (showInspector !== show) {
    customEvent(show ? 'inspector-expanded' : 'inspector-minimized');
    globalState.workbench.showInspector = show;
  }
}

export const hideContentPanel = () => (globalState.workbench.showContentPanel = false);
export const showContentPanel = () => (globalState.workbench.showContentPanel = true);
export const toggleContentPanel = () =>
  (globalState.workbench.showContentPanel = !globalState.workbench.showContentPanel);
