import { getAuth } from '@playful/api/firebase';
import { onAuthError } from '@playful/api/telemetry/events/authenticate';
import {
  AuthError,
  AuthProvider,
  browserPopupRedirectResolver,
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';

import { completeAuth } from './completeAuth';

export async function authenticateWithFederatedProvider(
  provider: AuthProvider,
  isNewUser: boolean,
  redirect: boolean,
) {
  try {
    const method = redirect ? signInWithRedirect : signInWithPopup;
    const credential = await method(getAuth(), provider, browserPopupRedirectResolver);
    if (credential) return completeAuth(credential);
  } catch (err: any) {
    onAuthError({ code: (err as AuthError).code, method: provider.providerId }, isNewUser);
    throw err;
  }
}

// Called to process the result of a federated auth redirect
export async function checkRedirectResult() {
  try {
    const credential = await getRedirectResult(getAuth(), browserPopupRedirectResolver);
    if (credential) return completeAuth(credential);
  } catch (err: any) {
    onAuthError({ code: (err as AuthError).code, method: 'unknown' }, false);
    throw new Error(err.message, { cause: err });
  }
}
