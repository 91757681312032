// Stuff added to the Window object for convenient access in the DevTools Console.

import { Component, ID, ProjectInfo, Properties, isReactor } from '@playful/runtime';
import type { ProjectModel } from '@playful/workbench/project/projectModel';

class PlayDevTools {
  projectInfos?: ProjectInfo[];
  projectModel?: ProjectModel;
  save?: () => void;

  // The currently selected component (or the first in the list if multiple).
  get sel(): Component | undefined {
    return this.selection?.[0];
  }

  // The entire selection.
  get selection(): Component[] | undefined {
    return this.projectModel?.getSelection();
  }
}

export const playDevTools = new PlayDevTools();
(window as any).play = playDevTools;

// Register our Reactor debug formatter with DevTools.
registerReactorFormatter();

// Make the debugger display of Reactors a little nicer via a DevTools custom formatter.
// https://www.mattzeunert.com/2016/02/19/custom-chrome-devtools-object-formatters.html
export function registerReactorFormatter() {
  if (typeof window === 'undefined') {
    return;
  }

  // https://stackoverflow.com/questions/55733647/chrome-devtools-formatter-for-javascript-proxy
  if (!Array.isArray((window as any).devtoolsFormatters)) {
    (window as any).devtoolsFormatters = [];
  }

  (window as any).devtoolsFormatters.push({
    header(value: any) {
      if (!isReactor(value) || Array.isArray(value)) {
        return null;
      }

      const properties: Properties = { name: value.name, ...value };

      return [
        'div',
        {},
        `{ ${Object.keys(properties)
          .filter((property) => typeof value[property] !== 'function')
          .slice(0, 5)
          .map((property) => {
            let v = value[property];
            switch (typeof v) {
              case 'object':
                if (isReactor(v)) {
                  v = `R[${v[ID]}]`;
                } else if (Array.isArray(v)) {
                  v = `Array`;
                } else {
                  v = `Object`;
                }
                break;

              case 'function':
                v = 'fn';
                break;

              default:
                v = JSON.stringify(v);
            }
            if (typeof v === 'string' && v.length > 10) {
              v = v.slice(0, 10) + '...';
            }
            return `${property}: ${v}`;
          })
          .join(', ')} }`,
      ];
    },

    hasBody() {
      return true;
    },

    body(value: any) {
      return [
        'ol',
        {
          style:
            'list-style-type:none; padding-left: 0px; margin-top: 4px; margin-bottom: 0px; margin-left: 12px',
        },
        ...Object.keys(value)
          .filter((property) => typeof value[property] !== 'function')
          .map((property) => [
            'li',
            { style: 'padding-bottom: 4px; white-space: nowrap; color: #ccc' },
            ['object', { object: value[property] }],
          ]),
      ];
    },
  });
}
