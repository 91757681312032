import { Stack as ChStack } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/react';
import React from 'react';

import type { StackProps } from './types';

/**
 * Layout component to stack items. See [Stack](https://v1.chakra-ui.com/docs/components/layout/stack) for the complete documentation.
 */
export const Stack = forwardRef(({ spacing = 3, ...props }: StackProps, ref) => {
  return <ChStack ref={ref} spacing={spacing} {...props} />;
});
