import { AppBar, AppBarLogo, AppBarProps, AppBarSection } from '@playful/design_system/AppBar';
import { Link } from '@playful/design_system/Link';
import React from 'react';
import { Link as WLink } from 'wouter';

export function Header({
  children,
  portalRef,
  ...boxProps
}: Omit<AppBarProps, 'divider'> & {
  portalRef?: React.MutableRefObject<HTMLElement | null>;
}) {
  return (
    <AppBar minH={{ base: 12, md: 14 }} {...boxProps} px={{ base: 3, lg: 4 }}>
      <WLink href='/'>
        <Link data-cy='appbar-logo' aria-label='playful logo'>
          <AppBarLogo />
        </Link>
      </WLink>
      <AppBarSection width='full' ref={portalRef}>
        {children}
      </AppBarSection>
    </AppBar>
  );
}
