import type { AuthProvider } from 'firebase/auth';

import { customEvent } from './customEvent';

type AuthSuccess = {
  method: AuthProvider['providerId'];
};

type AuthError = AuthSuccess & { code: string };

const SIGN_IN_SUCCESS_EVENT = 'signin-success';
const SIGN_IN_ERROR_EVENT = 'signin-error';

const SIGN_UP_SUCCESS_EVENT = 'signup-success';
const SIGN_UP_ERROR_EVENT = 'signup-error';

export function onAuthSuccess(props: AuthSuccess, isNewUser?: boolean) {
  customEvent(isNewUser ? SIGN_UP_SUCCESS_EVENT : SIGN_IN_SUCCESS_EVENT, props);
}

export function onAuthError(props: AuthError, isNewUser?: boolean) {
  customEvent(isNewUser ? SIGN_UP_ERROR_EVENT : SIGN_IN_ERROR_EVENT, props);
}
