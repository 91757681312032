import { Fade } from '@material-ui/core';
import { useUserContext } from '@playful/frontend/user/UserContext';
import React, { FunctionComponent, useCallback } from 'react';

import { removeTutorial, useActiveTutorial } from './tutorialsState';
import { TutorialStep } from './TutorialStep';
import type { TutorialState } from './types';

export const TutorialExperience: FunctionComponent = () => {
  const { activeTutorial, setActiveStep } = useActiveTutorial();
  const { toggleUserFlag } = useUserContext();

  const handleFinishTutorial = useCallback(
    (tutorial: TutorialState<any>) => {
      toggleUserFlag(tutorial.flag);
      removeTutorial(tutorial);
    },
    [toggleUserFlag],
  );

  return activeTutorial ? (
    <Fade in>
      <TutorialStep
        onFinishTutorial={handleFinishTutorial}
        setActiveStep={setActiveStep}
        tutorial={activeTutorial}
      />
    </Fade>
  ) : null;
};
