import { Typography, TypographyProps } from '@material-ui/core';
import type { AxiosError } from '@playful/api';
import React, { PropsWithChildren } from 'react';

import { useMainLayout } from '../layouts/MainLayout';
import { AccountBar } from '../user/AccountBar';

export const errCodes: Record<AxiosError['status'], string> = {
  404: 'Not found :(',
  500: 'Something went wrong',
};

export function getErrorProps(status: AxiosError['status'] = 500, suffix = ''): AxiosError {
  const fmtSuffix = suffix ? `: ${suffix}` : '';

  return { status, message: [errCodes[status] || errCodes[500], fmtSuffix].join('') };
}

export type ErrorMsgProps = PropsWithChildren<
  Pick<Partial<AxiosError>, 'message' | 'status'> & {
    variant?: TypographyProps['variant'];
  }
>;

export type ErrorPageProps = ErrorMsgProps & {
  portalToHeader: (renderFn: () => React.ReactNode) => React.ReactNode;
};

export function ErrorMsg({ children, variant, message, status }: ErrorMsgProps) {
  return (
    <>
      {children || (
        <Typography
          data-cy={`error_${status}`}
          component='h1'
          variant={variant}
          align='center'
          color='textSecondary'
          style={{ marginTop: '2rem' }}
        >
          {message}
        </Typography>
      )}
    </>
  );
}

export function ErrorPage({
  variant = 'h2',
  children,
  message = "Oops! There's been an error.",
  status = 500,
  portalToHeader,
}: ErrorPageProps) {
  const { Wrapper, Content } = useMainLayout();

  return (
    <Wrapper height='full'>
      {portalToHeader(() => (
        <AccountBar />
      ))}
      <Content>
        <ErrorMsg {...{ variant, message, status }}>{children}</ErrorMsg>
      </Content>
    </Wrapper>
  );
}
