import { HStack as ChHstack } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/react';
import React from 'react';

import type { HStackProps } from './types';

/**
 * Layout component to stack items horizontally. See [HStack](https://v1.chakra-ui.com/docs/components/layout/stack#hstack) for the complete documentation.
 */
export const HStack = forwardRef(({ spacing = 3, ...props }: HStackProps, ref) => {
  return <ChHstack ref={ref} spacing={spacing} {...props} />;
});
