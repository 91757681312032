import { useMediaQuery } from '@chakra-ui/react';

import { extraSmallScreenThreshold, smallScreenThreshold } from '../theme/foundations/breakpoints';

const maxWidthQuery = (threshold: number) => `(max-width:${threshold}px)`;

export function useIsSmallScreen(): boolean {
  const [isSm] = useMediaQuery(maxWidthQuery(smallScreenThreshold));
  return isSm;
}

export function isSmallScreen(): boolean {
  try {
    return window && window.matchMedia(`(max-width: ${smallScreenThreshold}px)`).matches;
  } catch (e) {
    return false;
  }
}

export function useIsExtraSmallScreen(): boolean {
  const [isXs] = useMediaQuery(maxWidthQuery(extraSmallScreenThreshold));
  return isXs;
}
