export function componentDocLink(component: string, anchor?: string) {
  return `docs/concepts/components/${escape(component)}${anchor ? `#${anchor}` : ''}`;
}

export function docPathForLink(link?: string): string {
  return ['/learning-center', link].filter(Boolean).join('/');
}

export function markdownLinkForDoc(title: string, link: string): string {
  return `[${title}](${docPathForLink(link)})`;
}
