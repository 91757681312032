import { initFirebase } from '@playful/api';
import { initializeLogRocket } from '@playful/api/telemetry/services';
import { enableTracing } from '@playful/api/telemetry/tracing';
import { loadConfig } from '@playful/config';
import React from 'react';
import { render } from 'react-dom';

import { App } from './App';

loadConfig().then((config) => {
  initFirebase(config);
  enableTracing(config);
  initializeLogRocket(config.logRocketId);

  render(<App />, document.getElementById('root'));
});
