import { getAuth } from '@playful/api/firebase';
import { onAuthError } from '@playful/api/telemetry/events/authenticate';
import { AuthError, signInWithEmailAndPassword } from 'firebase/auth';

import type { AuthSchema } from './AuthFlow/authSchema';
import { completeAuth } from './completeAuth';

export type SignInSchema = Pick<AuthSchema, 'email' | 'password'>;

export async function signIn({ email, password }: SignInSchema) {
  try {
    const credential = await signInWithEmailAndPassword(getAuth(), email, password);
    if (credential) {
      return completeAuth(credential);
    }
  } catch (err) {
    onAuthError({ code: (err as AuthError).code, method: 'email' }, false);
    throw err;
  }
}
