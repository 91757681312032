import type { Component } from '@playful/runtime';

// Convert e.g. "backgroundColor" to "Background Color".
export function humanizePropertyName(s: string): string {
  // There is no hope for underscore-prefixed properties.
  if (s[0] === '_') {
    return s;
  }

  // Insert a space before all caps.
  const spaced = s.replace(/([A-Z])/g, ' $1');

  // Uppercase the first character.
  return spaced.replace(/^./, (s) => s.toUpperCase());
}

export function qualifyProperty(qualifier: string | undefined, property: string): string {
  if (!qualifier) return property;
  return `${qualifier}.${property}`;
}

export function isQualifiedProperty(property: string): boolean {
  return property.indexOf('.') !== -1;
}

export function parseQualifiedProperty(property: string): {
  qualifier: string | undefined;
  property: string;
} {
  if (property.indexOf('.') === -1) {
    return { qualifier: undefined, property };
  }
  const [qualifier, unqualifiedProperty] = property.split('.');
  return { qualifier, property: unqualifiedProperty };
}

export function resolveQualifiedProperty(
  target: Component,
  property: string,
): { target: Component; property: string } {
  if (isQualifiedProperty(property)) {
    const { qualifier, property: unqualifiedProperty } = parseQualifiedProperty(property);
    target = target.getEffect(qualifier);
    property = unqualifiedProperty;
  }
  return { target, property };
}
