import {
  IconButton,
  DialogActions as MuiDialogActions,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const dialogActionIconButtonStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: (props: any) => props.backgroundColor,
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: (props: any) => props.backgroundColor,
      },
    },
    disabled: {},
  });

export interface DialogActionIconButtonProps
  extends WithStyles<typeof dialogActionIconButtonStyles> {
  backgroundColor?: string;
  children: React.ReactNode;
}

const IconButtonUnstyled = ({
  backgroundColor: _,
  children,
  classes,
  ...rest
}: DialogActionIconButtonProps) => {
  return (
    <IconButton {...rest} classes={classes}>
      {children}
    </IconButton>
  );
};

export const DialogActionIconButton = withStyles(dialogActionIconButtonStyles)(IconButtonUnstyled);

const dialogActionIconButtonGroupStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        marginRight: theme.spacing(2),
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  });
export interface DialogActionIconButtonGroupProps
  extends WithStyles<typeof dialogActionIconButtonGroupStyles> {
  backgroundColor?: string;
  children: React.ReactNode;
}
const IconButtonGroupUnstyled = ({ children, classes }: DialogActionIconButtonGroupProps) => {
  return <div className={classes.root}>{children}</div>;
};
export const DialogActionIconButtonGroup = withStyles(dialogActionIconButtonGroupStyles)(
  IconButtonGroupUnstyled,
);
