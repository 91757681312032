import { Avatar as ChakraAvatar } from '@chakra-ui/react';
import type { PublicUser } from '@playful/api/user';
import React from 'react';

import { MD5 } from '../utils/md5';

export interface AvatarProps {
  user?: PublicUser;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { user } = props;

  // TODO: Add an avatar image URL to PublicUser and use that instead of Gravatar.
  const emailHash = (user as any)?.email ? MD5((user as any).email) : undefined;

  // TODO: Tooltip w/ userName
  return (
    <ChakraAvatar
      size={'sm'}
      src={emailHash ? `https://www.gravatar.com/avatar/${emailHash}?d=mp` : undefined}
      name={user!.name}
    />
  );
};
