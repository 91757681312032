import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import lottieStyle from '../../assets/3-style.lottie.json';
import { useTutorialHeaderStyles } from '../../useTutorialStyles';

export const Style: React.FC<{ color: string }> = (props) => {
  const classes = useTutorialHeaderStyles();
  return (
    <div
      style={{
        backgroundColor: props.color,
        height: 143,
        width: '100%',
      }}
    >
      <Player id={'style-lottie'} autoplay loop src={lottieStyle} className={classes.stylePlayer} />
    </div>
  );
};
