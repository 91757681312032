import { LoaderReturn, useLoader } from '@playful/frontend/workbench/useLoader';
import type { Status } from '@playful/utils';
import { useCallback, useState } from 'react';

type SetOptimisticVal<R extends (...args: any[]) => Promise<any>> = (
  fn: () => Promise<Status<Awaited<ReturnType<R>>>>,
  newVal: Awaited<ReturnType<R>>,
) => Promise<Status<Awaited<ReturnType<R>>>>;

type OptimisticLoaderOptions<R extends (...args: any[]) => Promise<any>> = LoaderReturn<R> & {
  setValue: React.Dispatch<React.SetStateAction<Awaited<ReturnType<R>>>>;
};

/**
 * A light wrapper over `useLoader` that allows optimistic updates.
 *
 * @example
 * ```ts
 * function MyComponent() {
 *   const [name, setOptimisticName, { makeRequest }] = useOptimisticState<string>(
 *     (name: string) => Promise.resolve(name.toUpperCase()),
 *     'John',
 *   );
 *
 *   setOptimisticVal(() => makeRequest('helen'), 'Helen');
 * }
 * ```
 *
 * In this example, `name` is set to `John` until `setOptimisticName` is called, at
 * which point it will be set to the second parameter immediately (`Helen`) while the request
 * is in progress. If the request succeeds, the value will be set to the result of the request.
 * If the request fails, the value will be set back to `John`. The third item in the array is
 * the `LoaderReturn` object which provides all the handles available from 'useLoader', including
 * any errors returned.
 */
export function useOptimisticState<R extends (...args: any[]) => Promise<any>>(
  asyncFn: R,
  defaultVal?: Awaited<ReturnType<R>>,
): [Awaited<ReturnType<R>>, SetOptimisticVal<R>, OptimisticLoaderOptions<R>] {
  // TODO: better typing. `defaultVal` should be optional, but the typing for value should
  // be set to T if defaultValue IS provided, OR T | undefined if defaultVal IS NOT
  // provided. currently, it's up to the user to provide the correct type based on whether
  // they provide a default value or not.

  // TODO: we lose types when we call makeRequest. better typings.
  const [value, setValue] = useState<Awaited<ReturnType<R>>>(defaultVal!);
  const loaderRet = useLoader(asyncFn);

  const updateOptimistically = useCallback(
    async (fn: () => Promise<Status<Awaited<ReturnType<R>>>>, newVal: Awaited<ReturnType<R>>) => {
      const cachedVal = value;

      setValue(newVal);

      const ret = await fn();
      const [err, data] = ret;

      if (err) {
        setValue(cachedVal);
        return ret;
      }

      setValue(data!);
      return ret;
    },
    [value],
  );

  return [value, updateOptimistically, { ...loaderRet, setValue }];
}
