import { getAuth } from '@playful/api/firebase';
import { onAuthError } from '@playful/api/telemetry/events/authenticate';
import {
  AuthError,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import type { AuthSchema } from './AuthFlow/authSchema';
import { completeAuth } from './completeAuth';

export type SignUpSchema = Pick<AuthSchema, 'email' | 'password' | 'marketingConsent'>;

export async function signUp({ email, password, marketingConsent }: SignUpSchema) {
  let credential;
  let err;
  try {
    credential = await createUserWithEmailAndPassword(getAuth(), email, password);
  } catch (e) {
    err = e as AuthError;
  }

  // Do they already have an account (or are retrying from an authenticate error)?
  // Um, but also, if they enter the right pw, they get logged in
  if (err?.code === 'auth/email-already-in-use') {
    try {
      credential = await signInWithEmailAndPassword(getAuth(), email, password);
      err = undefined;
    } catch (signInErr) {
      // ignore
    }
  }

  if (err) {
    onAuthError({ code: err.code, method: 'email' }, false);
    throw err;
  }
  if (credential) {
    return completeAuth(credential, !!marketingConsent);
  }
}
