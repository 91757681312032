import { Status, fromPromise } from '@playful/utils';
import { useCallback, useState } from 'react';

export type AsyncFn<R> = (...args: any) => Promise<R>;

export type LoaderReturn<R extends (...args: any[]) => Promise<any>> = {
  isLoading: boolean;
  promise: R;
  res: Status<Awaited<ReturnType<R>>>;
  makeRequest: (...args: Parameters<R>) => Promise<Status<Awaited<ReturnType<R>>>>;
};

/**
 * Tiny hook that wraps `fromPromise` for async operations and provides loading states.
 */
export function useLoader<R extends (...args: any[]) => Promise<any>>(asyncFn: R) {
  const [isLoading, setLoading] = useState(false);
  const [res, setRes] = useState<Status<Awaited<ReturnType<R>>>>([null]);

  const makeRequest = useCallback(
    async (...args: Parameters<R>) => {
      setLoading(true);

      const _res = await fromPromise<Promise<ReturnType<R>>>(asyncFn(...args));

      setRes(_res);

      setLoading(false);

      return _res;
    },
    [asyncFn],
  );

  return {
    isLoading,
    promise: asyncFn,
    res,
    makeRequest,
  };
}
