import { authenticateUser, updateUserFlags } from '@playful/api';
import { onAuthError, onAuthSuccess } from '@playful/api/telemetry/events/authenticate';
import { AuthError, UserCredential, getAdditionalUserInfo } from 'firebase/auth';

import { parseInitialFlags } from './useUserFlags';

export async function completeAuth(credential: UserCredential, marketingOptIn?: boolean) {
  const {
    user: { uid },
  } = credential;

  const additionalUserInfo = getAdditionalUserInfo(credential);
  const isNewUser = additionalUserInfo?.isNewUser;
  const providerId = additionalUserInfo?.providerId ?? 'unknown';

  try {
    await authenticateUser({ userId: uid, marketingOptIn });
    const flagArr = parseInitialFlags();

    if (isNewUser && flagArr.length) {
      await updateUserFlags(uid, Object.fromEntries(flagArr.map((flag) => [flag, true])));
    }

    onAuthSuccess({ method: providerId }, isNewUser);
    return credential;
  } catch (err) {
    onAuthError({ code: (err as AuthError).code, method: providerId }, isNewUser);
    throw err;
  }
}
