import { useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import { useUserContext } from './user/UserContext';

const matchNextToProject = /^@([^/]+)\/(.+)$/;
const permittedProjectLinkUsers = new Set([
  'gallery', // used for alternate intro experiences
  'play',
]);
const permittedProjectLinkOrigins = new Set([
  // allows for the new splashes and marketing pages to be deployed to the corp
  // and linked in invites
  'https://playful.software',
]);

export function useRouteInterceptors() {
  const { isLoggedIn } = useUserContext();
  const [, setLocation] = useLocation();
  const [isRedirecting, setRedirecting] = useState(false);

  const redirect = (href: string) => {
    setRedirecting(true);
    window.location.href = href;
  };

  // TODO: we probably need to rethink our approach to this.
  // Support for ?next=@gallery/landing_x or ?next=https://playful.software/landing_x
  // which are used for targeted product/marketing work. For security, we restrict the
  // redirect to either a page on the corp site or to a play project owned by the staff
  // @gallery account to prevent someone from receiving a valid link and using it to
  // create a new link with ?next=https://buy-play-premium.with-just-your-ssn-and-credit-card.com
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const next = searchParams.get('next');

    if (isLoggedIn && next) {
      const [, user] = matchNextToProject.exec(next) || [];

      if (user && permittedProjectLinkUsers.has(user)) {
        redirect('/' + next);
        return;
      }

      try {
        const { origin } = new URL(next);
        if (permittedProjectLinkOrigins.has(origin)) {
          redirect(next);
          return;
        }
      } catch (e) {
        // TODO: Explore more comprehensive alternatives than using `next` for internal re-routing
        // use cases. `useRouteInterceptors` is not really designed to accommodate this (see the
        // above comment for what it was designed for, and
        // https://github.com/Playful-Software/play/pull/2765#issuecomment-1215848360 for context
        const isPath = next?.startsWith('/');

        if (isPath) return setLocation(next);

        console.warn(`Exception processing next URL. url: ${next}`, e);
      }

      setLocation('/');
    }
  }, [isLoggedIn, setLocation]);

  return { isRedirecting };
}
