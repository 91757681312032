import { proxy, useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';

import type { TutorialState, TutorialsState } from './types';

const state = proxy<TutorialsState>({
  activeTutorial: undefined,
  needsUpdate: false,
  tutorials: [],
});

subscribeKey(state, 'needsUpdate', (needsUpdate) => {
  if (needsUpdate) {
    if (!state.activeTutorial) {
      state.activeTutorial = pickActiveTutorial();
    }
    state.needsUpdate = false;
  }
});

function pickActiveTutorial() {
  /** TODO:
   * - ordering/priority?
   * - limits (how many show, delay between, etc)
   */
  return state.tutorials[0];
}

// Mutators

export function addTutorial(tutorial: TutorialState<any>) {
  tutorial.activeStepIndex = 0;
  state.tutorials = state.tutorials.concat(tutorial);
  setNeedsUpdate();
}

export function removeTutorial(tutorial: TutorialState<any>) {
  state.tutorials = state.tutorials.filter((it) => it.id !== tutorial.id);
  if (state.activeTutorial?.id === tutorial.id) {
    state.activeTutorial = pickActiveTutorial();
  }
  setNeedsUpdate();
}

export function setNeedsUpdate() {
  if (!state.needsUpdate) {
    state.needsUpdate = true;
  }
}

// Hooks

export function useActiveTutorial() {
  const { activeTutorial } = useSnapshot(state);

  return {
    activeTutorial,

    setActiveStep(step: number) {
      if (state.activeTutorial?.id !== activeTutorial?.id) {
        throw new Error('mismatch');
      }
      if (state.activeTutorial) {
        state.activeTutorial.activeStepIndex = step % state.activeTutorial.steps.length;
      }
    },
  };
}
