import { Player } from '@lottiefiles/react-lottie-player';
import { Box, BoxProps } from '@playful/design_system/Box';
import { Text } from '@playful/design_system/Text';
import React from 'react';

import lottieShare from '../lottie/playful-logo-spin.lottie.json';

export function PlayfulSpinProgress({ message, ...props }: BoxProps & { message: string }) {
  return (
    <Box
      display='flex'
      height='full'
      flexDir='column'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <Box as={Player} width='24' autoplay loop src={lottieShare} />
      <Text mt={2} fontSize='large' fontWeight='bold'>
        {message}
      </Text>
    </Box>
  );
}
