import { DialogTitle as MuiDialogTitle, WithStyles, createStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';

export const dialogCardTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      height: 140,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof dialogCardTitleStyles> {
  children: React.ReactNode;
}

export const DialogCardTitle = withStyles(dialogCardTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, ...rest } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...rest}>
      {children}
    </MuiDialogTitle>
  );
});
