import { BoxProps, forwardRef } from '@chakra-ui/react';
import React from 'react';
import { Link, useRoute } from 'wouter';

import { Box } from '../Box';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { HStack } from '../HStack';
import { Input } from '../Input';
import { ButtonLink, ButtonLinkProps } from '../Link';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '../Popover';
import { Stack } from '../Stack';
import { Switch } from '../Switch';
import { Text } from '../Text';
import type { AppBarProps } from './types';

export const AppBar = forwardRef((props: AppBarProps, ref) => {
  const { children, ...appBarProps } = props;

  return (
    <HStack
      ref={ref}
      as='header'
      color='white'
      bg='gray.900'
      alignItems='center'
      display='flex'
      px={{ base: 2, md: 4 }}
      minH={12}
      zIndex={1100}
      position='sticky'
      spacing={{ base: 2, md: 3 }}
      {...appBarProps}
    >
      {children}
    </HStack>
  );
});

export const AppBarSection = forwardRef((props: AppBarProps, ref) => {
  const { children, ...appBarSectionProps } = props;

  return (
    <HStack ref={ref} position='relative' spacing={1} {...appBarSectionProps}>
      {children}
    </HStack>
  );
});

export function AppBarLogo(props: BoxProps) {
  return (
    <Box display='flex' borderRadius='full' alignItems={'center'} {...props}>
      <Box
        as='svg'
        w={{ base: 8, md: 10 }}
        h={{ base: 8, md: 10 }}
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
          fill='url(#logo-gradient)'
        />
        <path
          d='M24.479 18.495C24.479 16.423 23.43 15.148 21.859 15.148C20.286 15.148 19.238 16.423 19.238 18.495C19.238 20.567 20.228 21.843 21.858 21.843C23.488 21.843 24.478 20.567 24.478 18.495H24.479ZM13.008 10.58H17.316C18.016 10.58 18.656 11.005 18.83 11.642L19.354 13.342H19.413C20.344 11.271 22.266 10.208 24.537 10.208C28.497 10.208 31 13.343 31 18.549C31 23.703 28.438 26.891 24.537 26.891C22.324 26.891 20.577 25.828 19.587 23.863H19.529V33H13.008V10.579V10.58Z'
          fill='#000000'
        />
        <path
          d='M13.096 9.11999H23.013L27.972 18.237V23.232L21.085 22.982L13.785 19.486L13.096 9.12099V9.11999ZM13.096 32.972L8 27.727H14.474V32.972H13.096ZM24.21 7.24599L23.84 6.87299C23.962 6.93699 24.087 7.06599 24.21 7.24599L28.936 11.993V14.241L25.768 11.743C25.498 10.518 24.864 8.19299 24.21 7.24599ZM13.647 5.99899L14.887 7.37299L14.474 8.24699H14.198L13.648 5.99899H13.647Z'
          fill='#000000'
        />
        <path
          d='M8.17499 27.634V5.47799H12.309C12.949 5.47799 13.532 5.84999 13.649 6.43499L14.172 8.24099H14.405L14.463 8.13499C15.337 6.16899 17.142 5.05299 19.413 5.05299C23.313 5.05299 25.759 8.18799 25.759 13.235C25.759 18.283 23.314 21.418 19.413 21.418C17.258 21.418 15.57 20.355 14.638 18.442L14.58 18.389H14.347L14.289 18.495V27.527H8.17499V27.634ZM16.792 9.83499C15.104 9.83499 14.056 11.216 14.056 13.342C14.056 15.467 15.104 16.795 16.792 16.795C18.481 16.795 19.587 15.414 19.587 13.342C19.587 11.216 18.481 9.83499 16.792 9.83499Z'
          fill='#F0F2F9'
        />
        <path
          d='M19.47 5.266C23.314 5.266 25.643 8.347 25.643 13.342C25.643 18.336 23.256 21.417 19.471 21.417C17.375 21.417 15.744 20.408 14.813 18.548L14.754 18.389H14.231V27.527H8.29V5.638H12.308C12.89 5.638 13.356 6.01 13.531 6.488L14.055 8.188L14.113 8.4H14.579L14.637 8.241C15.51 6.328 17.199 5.266 19.47 5.266ZM16.793 16.9C18.598 16.9 19.705 15.466 19.705 13.287C19.705 11.109 18.54 9.675 16.793 9.675C15.047 9.675 13.882 11.109 13.882 13.287C13.94 15.519 15.047 16.9 16.793 16.9ZM19.471 5C17.2 5 15.337 6.063 14.347 8.135H14.289L13.823 6.435C13.648 5.797 13.008 5.372 12.309 5.372H8V27.74H14.463V18.602H14.522C15.512 20.567 17.258 21.63 19.471 21.63C23.431 21.63 25.934 18.442 25.934 13.288C25.934 8.135 23.372 5 19.471 5ZM16.792 16.636C15.22 16.636 14.172 15.361 14.172 13.288C14.172 11.216 15.162 9.941 16.792 9.941C18.422 9.941 19.412 11.216 19.412 13.288C19.471 15.36 18.422 16.636 16.792 16.636Z'
          fill='#FFFFFF'
        />
        <defs>
          <radialGradient
            id='logo-gradient'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(18 18) rotate(55.751) scale(28.4297 168.569)'
          >
            <stop stopColor='#FF01E6' />
            <stop offset='0.69' stopColor='#FFF500' />
          </radialGradient>
        </defs>
      </Box>
    </Box>
  );
}

AppBarLogo.displayName = 'AppBarLogo';

export const AppBarNavigationItem = forwardRef(
  ({ href, isActive, ...props }: ButtonLinkProps & { href: string; isActive?: boolean }, ref) => {
    const [isRouteActive] = useRoute(href);

    // Turns out wouter's <Link /> component can't take a ref...
    return (
      <Link href={href}>
        <ButtonLink
          ref={ref}
          colorScheme={'gray'}
          mx={{ base: 0, lg: 1 }}
          variant='ghost'
          size='sm'
          borderRadius='full'
          isActive={isActive ?? isRouteActive}
          color={'gray.100'}
          {...props}
        />
      </Link>
    );
  },
);

AppBarNavigationItem.displayName = 'AppBarNavigationItem';

export function AppBarShare() {
  return (
    <Box>
      <Popover placement='bottom-end'>
        <PopoverTrigger>
          <Button variant='solid' colorScheme='primary'>
            {'Share'}
          </Button>
        </PopoverTrigger>
        <PopoverContent color='gray.800' w='25rem'>
          <PopoverArrow />
          <PopoverBody px={0}>
            <Stack spacing={4}>
              <HStack px={6}>
                <Stack spacing={1} flex={1}>
                  <Heading>{'Share to the web'}</Heading>
                  <Text fontSize='sm' color='gray.500'>
                    {'Publish and share a link with anyone'}
                  </Text>
                </Stack>
                <Switch />
              </HStack>
              <HStack spacing={2} px={6}>
                <Input value='Something' flex='1' readOnly />
                <Button flex='none'>{'Copy'}</Button>
              </HStack>
              <Divider />
              <Stack spacing={3}>
                <HStack px={6}>
                  <Stack spacing={1} flex={1}>
                    <Heading>{'Allow remixing'}</Heading>
                    <Text fontSize='sm' color='gray.500'>
                      {"Let's anyone create a copy of your project and remix it as their own"}
                    </Text>
                  </Stack>
                  <Switch />
                </HStack>
                <HStack px={6}>
                  <Stack spacing={1} flex={1}>
                    <Heading>{'Share on playful'}</Heading>
                    <Text fontSize='sm' color='gray.500'>
                      {
                        'Visible for all Playful users to see for inspiration. Also shared on your public profile page'
                      }
                    </Text>
                  </Stack>
                  <Switch />
                </HStack>
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

AppBarShare.displayName = 'AppBarShare';
