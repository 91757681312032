import { createContext, useContext } from 'react';

/**
 * Creates a generic context that you can type.
 * PROPS to: https://www.rivoltafilippo.com/typing-react-context-to-avoid-an-undefined-default-value
 *
 * TODO: displayName should not be optional for debugging purposes
 */
export const createGenericContext = <T>(opts?: { displayName?: string }) => {
  const { displayName } = opts ?? {};

  // Create a context with a generic parameter or undefined
  const genericContext = createContext<T | undefined>(undefined);

  genericContext.displayName = displayName;

  // Check if the value provided to the context is defined or throw an error
  const useGenericContext = () => {
    const contextIsDefined = useContext(genericContext);

    if (!contextIsDefined) {
      throw new Error(
        `This hook must be used within ${genericContext.displayName ?? 'a context.'}`,
      );
    }

    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};
