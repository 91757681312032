import { Box, forwardRef, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import { Header } from './Header';

// This is a workaround for preventing bouncing while scrolling in safari. Because safari
// does not, as of today, respect `overscroll-behavior: none`, we add `overflow: hidden` to
// the body, and make *only* the content area scrollable. This prevents the bounce effect on
// the body; while there is still a bounce effect within this container, it's less
// noticeable, and we prevent it at the workbench level, which is most important.
//
// TODO: when safari supports `overscroll-behavior`, we can simplify a lot.
const ContentOverflow = forwardRef((props: any, ref) => {
  const headerOffset = useBreakpointValue({ base: 48, md: 56 });

  return (
    <Box
      ref={ref}
      maxHeight={`calc(100vh - ${headerOffset}px)`}
      overflow='auto'
      height='100%'
      {...props}
    />
  );
});

/**
 * The main layout, as responsive, composable elements (vs HOC).
 */
export function useMainLayout() {
  return {
    Wrapper: Box,
    Header: Header,
    Content: ContentOverflow,
  };
}
