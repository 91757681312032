import { CloseIcon } from '@chakra-ui/icons';
import { CircularProgress, Image } from '@chakra-ui/react';
import { FEATURE_UNDER_CONSTRUCTION } from '@playful/api';
import { Box } from '@playful/design_system/Box';
import { IconButton } from '@playful/design_system/Button';
import { FormControl, FormLabel } from '@playful/design_system/Form';
import { Heading } from '@playful/design_system/Heading';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@playful/design_system/Modal';
import { Switch } from '@playful/design_system/Switch';
import { Text } from '@playful/design_system/Text';
import { useIsSmallScreen } from '@playful/design_system/utils';
import type { ProjectInfo } from '@playful/runtime';
import { getProjectInfosByTag } from '@playful/workbench/project/projectStorage';
import { PREVIEW } from '@playful/workbench/project/resources';
import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';

import { useResourceDataUrl } from './hooks/useResource';
import { useUserContext } from './user/UserContext';

interface ChooseTemplateDialogProps {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

const ChooseTemplateDialog: React.FC<ChooseTemplateDialogProps> = (props) => {
  const { onDone } = props;

  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDarkTemplates, setShowDarkTemplates] = useState<boolean>(false);

  const { hasFlag } = useUserContext();
  const underConstruction = hasFlag(FEATURE_UNDER_CONSTRUCTION);

  const isSmallScreen = useIsSmallScreen();
  // Prevent height collapse when either loading templates or switching dark templates on
  const minInnerHeight = isSmallScreen ? 380 : 474;

  // Fetch `_template` tagged projects on initial render
  useEffect(() => {
    setIsLoading(true);

    getProjectInfosByTag('_template').then((infos) => {
      // Filter out welcome templates.
      infos = (infos ?? []).filter((info) => !info.tags!['welcome']);

      setProjectInfos(infos);
      setIsLoading(false);
    });
  }, []);

  // Filter and set templates to display to user
  const visibleTemplates = useMemo(() => {
    // Show dark or light templates.
    let templates = projectInfos.filter((info) => showDarkTemplates == !!info.tags!['dark']);

    // If on the golden path, hide the physics starter projects
    if (!underConstruction) {
      // Only show templates tagged with 'golden'; others like the Physics Starter templates will be hidden
      templates = templates.filter((info) => info.tags!['golden']);
    }

    // Sort templates based on date, which will put the basic portrait, landscape templates first
    templates.sort((a: ProjectInfo, b: ProjectInfo) => {
      const dateA = a.created;
      const dateB = b.created;
      if (dateA > dateB) {
        return 1;
      } else if (dateA < dateB) {
        return -1;
      }
      return 0;
    });

    return templates;
  }, [showDarkTemplates, underConstruction, projectInfos]);

  const templateCards = useMemo(() => {
    return visibleTemplates.map((info) => (
      <TemplateCard key={info.id} projectInfo={info} onDone={(info) => onDone(info, 'Project')} />
    ));
  }, [onDone, visibleTemplates]);

  return (
    <Modal isOpen onClose={() => onDone()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={'md'}>
        <ModalBody p={isSmallScreen ? 6 : 8}>
          <Box display='flex' pb={6}>
            <Heading fontSize={'xl'} flexGrow={1} color='gray.700'>
              Choose a template to begin
            </Heading>
            <IconButton
              aria-label='Close'
              icon={<CloseIcon />}
              justifySelf='flex-end'
              position='static'
              variant='ghost'
              onClick={() => onDone()}
              size='sm'
              mt={-1}
              isRound
            />
          </Box>
          {isLoading ? (
            <Box display='flex' justifyContent='center' alignContent='center' minH={minInnerHeight}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display='flex'
              flexWrap='wrap'
              gap={4}
              justifyContent='center'
              minH={minInnerHeight}
            >
              {templateCards}
            </Box>
          )}
          <Box mt={8}>
            <FormControl display='flex'>
              <Switch
                color='gray.400'
                id='template-mode'
                onChange={(event) => setShowDarkTemplates(event.target.checked)}
              />
              <FormLabel
                fontSize='sm'
                htmlFor='template-mode'
                fontWeight='bold'
                color='gray.500'
                ml={2}
              >
                dark templates
              </FormLabel>
            </FormControl>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface ChooseTemplateProps {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

export const ChooseTemplate: React.FC<ChooseTemplateProps> = (props) => {
  return <ChooseTemplateDialog onDone={props.onDone} />;
};

export interface TemplateCardProps {
  projectInfo: ProjectInfo;
  onDone(projectInfo: ProjectInfo): void;
}

export const TemplateCard: React.FC<TemplateCardProps> = (props) => {
  const { projectInfo, onDone } = props;

  const previewUrl = useResourceDataUrl(projectInfo.project, PREVIEW).url;

  const isSmallScreen = useIsSmallScreen();
  const maxHeight = isSmallScreen ? 138 : 185;

  // Use this to dynamically set the width AFTER image has loaded
  // Otherwise TemplateCards may not line up when a template's name is longer and therefore takes up
  // additional width and is wider the preview image
  const [maxWidth, setMaxWidth] = useState('100%');

  const onLoad = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setMaxWidth(e.target.offsetWidth);
  };

  return (
    <Box
      onClick={() => onDone(projectInfo)}
      display='flex'
      flexDirection={'column'}
      gap={2}
      cursor='pointer'
      maxW={maxWidth}
    >
      {previewUrl && (
        <Box
          position={'relative'}
          display='flex'
          justifyContent='center'
          transition={'0.3s'}
          _hover={{
            filter: 'brightness(0.7)',
          }}
        >
          <Image
            borderColor='gray.100'
            borderWidth={1}
            borderStyle='solid'
            borderRadius='base'
            src={previewUrl}
            title={projectInfo.title}
            alt={projectInfo.title}
            h={maxHeight}
            onLoad={onLoad}
          />
        </Box>
      )}
      <Text
        fontSize='md'
        fontWeight='normal'
        color='gray.700'
        textAlign={isSmallScreen ? 'center' : 'unset'}
        onClick={() => onDone(projectInfo)}
      >
        {projectInfo.title.toLowerCase()}
      </Text>
    </Box>
  );
};
