import type { User } from '@playful/api/user';

// the bare required properties for our default users
export const anonymousUser: User = {
  id: 'anonymous',
  name: 'anonymous',
  email: '',
  userType: 'user',
  uploadsResourceId: '',
};
