import type { SnackbarOrigin } from '@material-ui/core';
import { generateUUID } from '@playful/runtime/util';
import React from 'react';
import { proxy, ref, useSnapshot } from 'valtio';

import type { AppToast } from './AppToasts';
import { CustomSnackbar } from './Snackbars';

type AppSnacksProps = Record<string, unknown>;

type SnackVariants = 'success' | 'error' | 'info' | 'warning';

export type AppSnack = {
  id: string;
  variant: SnackVariants;
  message: React.ReactNode;
  message2?: React.ReactNode;
  anchorOrigin?: SnackbarOrigin;
};

const initialSnackState = {
  list: [] as AppSnack[],
  expired: [] as AppSnack[],
  toasts: [] as AppToast[],
};

export const snackState = proxy(initialSnackState);

export function makeSnack(options: {
  id?: string;
  variant: SnackVariants;
  message: React.ReactNode;
  message2: React.ReactNode;
  anchorOrigin?: SnackbarOrigin;
}): AppSnack {
  const { variant, message, message2, anchorOrigin, id } = options;
  return {
    id: id || generateUUID(),
    variant,
    message,
    message2,
    anchorOrigin,
  };
}

export function addSnackIfNotExist(
  id: string,
  variant: SnackVariants,
  message: React.ReactNode,
  message2?: React.ReactNode,
  anchorOrigin?: SnackbarOrigin,
): void {
  const exists =
    snackState.list.find((s) => s.id === id) || snackState.expired.find((s) => s.id === id);
  if (exists) {
    return;
  }
  addSnack(variant, message, message2, anchorOrigin, id);
}

export function addSnack(
  variant: SnackVariants,
  message: React.ReactNode,
  message2?: React.ReactNode,
  anchorOrigin?: SnackbarOrigin,
  id?: string,
): void {
  if (typeof message === 'object') {
    try {
      message = JSON.stringify(message);
    } catch (e) {
      console.error(message, e);
    }
  }

  // Use Valtio's ref() to prevent messages that are ReactNodes from being proxy-wrapped
  // which seems to confuse React downstream.
  snackState.list.push(ref(makeSnack({ variant, message, message2, anchorOrigin, id })));
}

export function addErrorSnack(message: string): void {
  addSnack('error', message);
}

export function addInfoSnack(message: string): void {
  addSnack('info', message);
}

const AppSnacks: React.FC<AppSnacksProps> = () => {
  const snacks = useSnapshot(snackState);

  const handleClose = (id: string) => () => {
    const snack = snackState.list.find((snack) => snack.id === id);
    snackState.list = snackState.list.filter((snack) => snack.id !== id);
    if (snack) {
      snackState.expired = [...snackState.expired, snack];
    }
  };

  return (
    <>
      {snacks.list.map((snack) => (
        <CustomSnackbar
          key={snack.id}
          open={true}
          onClose={handleClose(snack.id)}
          message={snack.message}
          message2={snack.message2}
          variant={snack.variant}
          anchorOrigin={snack.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
        />
      ))}
    </>
  );
};

export default AppSnacks;
