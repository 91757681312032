import { getConfig } from '@playful/config';
import type { Content, Point } from '@playful/runtime';

// This attempts (strongly) to turn a string into a valid url with a protocol. It uses
// `new URL()` to do this instead of a regex, which is a reasonable effort to provide a
// valid url (for instance, it does not check for many illegal scenarios, like using `?`
// multiple times)
export const makeUrl = (url: string) => {
  try {
    return new URL(decodeURIComponent(url)).toString();
  } catch (e) {
    // will succeed 99% of the time, if it's not totally butchered (like spaces, extra
    // colons, etc)
    try {
      return new URL(decodeURIComponent('http://' + url)).toString();
    } catch (e) {
      return;
    }
  }
};

// buildAbsoluteUrl
// given a path fragment, this function will append the base url provided by ENV variables
// to provide a fully qualified absolute location. This is especially necessary for custom
// domains and dependent services.
export function buildAbsoluteUrl(path: string): string {
  try {
    return new URL(path, getConfig().baseUrl).toString();
  } catch {
    return '/' + path;
  }
}

/**
 * Clamps a number to a specific range. I.e. if the range is 10-20
 * 9 will be 10, 21 will be 20.
 */
export const clamp = (min: number, max: number) => (value: number) =>
  Math.max(min, Math.min(value, max));

/**
 * Checks for null or undefined, lifted from https://ramdajs.com/docs/#isNil
 */
export const isNil = (x: any) => x == null;

// Handy handler utils

/**
 * Returns a handler that will pass in `true` as the first arg to a wrapped function.
 */
export const withT =
  <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) =>
  (...args: Args) =>
    fn(true, ...args);

/**
 * Returns a handler that will pass in `false` as the first arg to a wrapped function.
 */
export const withF =
  <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) =>
  (...args: Args) =>
    fn(false, ...args);

/**
 * Returns a handler that will pass in `null` as the first arg to a wrapped function.
 */
export const withNull =
  <Args extends unknown[]>(fn: (a: null, ...args: Args) => any) =>
  (...args: Args) =>
    fn(null, ...args);

/**
 * Returns a handler that will toggle its first arg (boolean) and pass it as the first arg to a wrapped function.
 */
export const toggle =
  <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) =>
  (v: boolean, ...args: Args) =>
    fn(!v, ...args);

export function noop(): void {
  // do nothing
}

/**
 * Returns the distance between two points.
 */
export function dist(a: Point, b: Point) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function between(n: number, min: number, max: number) {
  return n >= min && n <= max;
}

export function pointsEqual(a?: Point, b?: Point): boolean {
  return Boolean(a && b && a.x === b.x && a.y === b.y);
}

export function isAudioType(content: Content) {
  return (
    content.type === 'audio' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('audio'))
  );
}

export function isVideoType(content: Content) {
  return (
    content.type === 'video' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('video'))
  );
}

export function isImageType(content: Content) {
  return (
    content.type === 'image' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('image'))
  );
}
