import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import welcomeSvg from '../../assets/1a-welcome.svg?url';
import lottieSuperhero from '../../assets/1b-welcome.lottie.json';
import { useTutorialHeaderStyles } from '../../useTutorialStyles';

export const Welcome: React.FC<{ color: string }> = (props) => {
  const classes = useTutorialHeaderStyles();
  return (
    <div>
      <Player
        id={'welcome-lottie'}
        autoplay
        loop
        src={lottieSuperhero}
        className={classes.welcomePlayer}
      />
      <img src={welcomeSvg} />
    </div>
  );
};
