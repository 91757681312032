import type { ResourceId, ResourceUrlOptions } from '@playful/runtime';
import { Resource } from '@playful/workbench/project/resources';
import { useEffect, useState } from 'react';

export function useResource(initialResId: ResourceId): {
  res?: Resource;
  setResId: (resId: ResourceId) => void;
} {
  const [resId, setResId] = useState<ResourceId>(initialResId);
  const [res, setResource] = useState<Resource | undefined>(undefined);

  useEffect(() => {
    const fetchResource = async () => {
      const res = await Resource.get(resId);
      setResource(res);
    };
    fetchResource();
  }, [resId]);

  return { res, setResId };
}

export function useResourceDataUrl(
  initialResId: ResourceId,
  initialKey: string,
  initialOpts?: ResourceUrlOptions,
): {
  url?: string;
  setResId: (resId: ResourceId) => void;
  setKey: (key: string) => void;
  setOpts: (opts: ResourceUrlOptions) => void;
} {
  const [key, setKey] = useState<string>(initialKey);
  const { res, setResId } = useResource(initialResId);
  const [opts, setOpts] = useState<ResourceUrlOptions | undefined>(initialOpts);
  const url = res?.getDataUrl(key, opts);
  return { url, setResId, setKey, setOpts };
}

export function useResourceDataUrlAuth(
  initialResId: ResourceId,
  initialKey: string,
  initialOpts?: ResourceUrlOptions,
): {
  url?: string;
  setResId: (resId: ResourceId) => void;
  setKey: (key: string) => void;
  setOpts: (opts: ResourceUrlOptions) => void;
} {
  const [resId, setResId] = useState<string>(initialResId);
  const [key, setKey] = useState<string>(initialKey);
  const [opts, setOpts] = useState<ResourceUrlOptions | undefined>(initialOpts);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getUrl = async () => {
      try {
        setUrl(await Resource.getDataUrlAuth(resId, key, opts));
      } catch (e) {
        console.error(e);
      }
    };
    getUrl();
  }, [resId, key, opts]);

  return { url, setResId, setKey, setOpts };
}
