import React from 'react';

import elementsVideo from '../../assets/2-elements.mp4';

export const Elements: React.FC<{ color?: string }> = () => {
  return (
    <div>
      <video
        playsInline
        width={326}
        height={140}
        autoPlay
        loop
        muted
        preload={'metadata'}
        src={elementsVideo}
      >
        <source src={`${elementsVideo}#t=0.1`} type='video/mp4' />
      </video>
    </div>
  );
};
