import { useUserContext } from '@playful/frontend/user/UserContext';
import { generateUUID } from '@playful/runtime/util';
import { createRef, useEffect } from 'react';
import { proxy, ref as valitoRef } from 'valtio';

import { addTutorial, removeTutorial, setNeedsUpdate } from './tutorialsState';
import type { Tutorial, TutorialConfig, TutorialState, UseTutorialParams } from './types';

export const createTutorial = <Name extends string, Callout extends string>(
  config: TutorialConfig<Name, Callout>,
): Tutorial<Name, Callout> => {
  const id = generateUUID();

  const namedCalloutRefs: TutorialState<Callout>['namedCallouts'] = {};
  const callouts = config.steps.reduce((ret, step) => {
    const name = step?.calloutOptions?.name;

    // unfortunate naming colision ahead.
    if (name && !ret[name]) {
      const reactRef = createRef<any>();
      ret[name] = valitoRef(reactRef);
      namedCalloutRefs[`${name}TutorialRef`] = reactRef;
    }
    return ret;
  }, {} as Partial<TutorialState<Callout>['callouts']>) as TutorialState<Callout>['callouts'];

  const tutorialState = proxy<TutorialState<Callout>>({
    activeStepIndex: 0,
    callouts,
    params: undefined,
    flag: config.flag,
    id,
    namedCallouts: namedCalloutRefs,
    steps: valitoRef(config.steps),
  });

  return {
    [`use${config.name}Tutorial`](params?: UseTutorialParams<Callout, any>) {
      const { hasFlag } = useUserContext();

      useEffect(() => {
        if (hasFlag(config.flag)) {
          return;
        }

        addTutorial(tutorialState);

        if (tutorialState.params !== params) {
          tutorialState.params = params;
        }

        return () => removeTutorial(tutorialState);
      }, [hasFlag, params]);
    },

    [`use${config.name}TutorialCallouts`]() {
      const { hasFlag } = useUserContext();

      if (hasFlag(config.flag)) {
        return {};
      }

      setNeedsUpdate();
      return namedCalloutRefs;
    },
  };
};
