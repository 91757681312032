import { AppBarNavigationItem, AppBarSection } from '@playful/design_system/AppBar';
import { Box } from '@playful/design_system/Box';
import { Button } from '@playful/design_system/Button';
import { ChevronDownIcon } from '@playful/design_system/Icon';
import { Link } from '@playful/design_system/Link';
import { Menu, MenuButton, MenuItem, MenuList } from '@playful/design_system/Menu';
import { useIsSmallScreen } from '@playful/design_system/utils';
import React, { PropsWithChildren } from 'react';

type RouteItem = {
  path: string;
  label: string;
};

export type HeaderProps = { navItems: RouteItem[]; activePath: string };

export function ExplorerHeader({ navItems, activePath, children }: PropsWithChildren<HeaderProps>) {
  const isSmallScreen = useIsSmallScreen();

  const currentNavItem = navItems.find((item) => item.path === activePath) || navItems[0];

  if (!isSmallScreen) {
    return (
      <>
        <Box flexShrink={0}>
          {navItems.map(({ path, label }) => (
            <AppBarNavigationItem isActive={path === activePath} key={path} href={path}>
              {label}
            </AppBarNavigationItem>
          ))}
        </Box>
        {children}
      </>
    );
  }

  return (
    <AppBarSection justifyContent={'space-between'} width={'100%'}>
      <Menu closeOnBlur autoSelect={false}>
        <Box>
          <MenuButton
            as={Button}
            size={'sm'}
            variant={'solid'}
            bg={'gray.700'}
            _active={{ bg: 'gray.700' }}
            _focus={{ bg: 'gray.700' }}
            _hover={{ bg: 'gray.700' }}
            color={'gray.50'}
            rightIcon={<ChevronDownIcon />}
            minWidth={0}
            sx={{
              span: {
                width: 'auto',
              },
            }}
            fontSize={'xs'}
            data-cy={'header-menu-button'}
          >
            {currentNavItem.label}
          </MenuButton>
        </Box>
        <MenuList bg={'white'} color={'gray.900'} minWidth={0}>
          {navItems.map(({ path, label }) => (
            <MenuItem
              as={Link}
              key={path}
              href={path}
              _focus={{ bg: 'gray.50' }}
              _active={{ bg: 'gray.50' }}
            >
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {children}
    </AppBarSection>
  );
}
