import { buildUserRoute } from '@playful/utils';
import React, { Suspense, lazy } from 'react';
import { Route, Switch, useLocation } from 'wouter';

import { ExplorerHeader } from '../layouts/ExplorerLayout';
import { useMainLayout } from '../layouts/MainLayout';
import { Loading } from '../Loading';
import { ProtectedRoute } from '../ProtectedRoute';
import { ROUTE_GALLERY, ROUTE_HOME, ROUTE_MY_PROJECTS } from '../routes';
import { AccountBar } from '../user/AccountBar';
import { useUserContext } from '../user/UserContext';

const HomePage = lazy(() => import('../pages/HomePage' /* webpackChunkName: "HomePage" */));
const GalleryPage = lazy(
  () => import('../pages/GalleryPage' /* webpackChunkName: "GalleryPage" */),
);
const MyProjects = lazy(() => import('../pages/MyProjects' /* webpackChunkName: "MyProjects" */));

export function ExplorerSubRoutes({
  portalToHeader,
}: {
  portalToHeader: (renderFn: () => React.ReactNode) => React.ReactNode;
}) {
  const { Wrapper, Content } = useMainLayout();
  const [activePath] = useLocation();
  const { isLoggedIn, user } = useUserContext();

  const explorerSubRoutes = {
    home: { path: ROUTE_HOME, label: 'home' },
    myProjects: { path: ROUTE_MY_PROJECTS, label: 'my projects' },
    profile: { path: buildUserRoute(user.name), label: 'my profile' },
    gallery: { path: ROUTE_GALLERY, label: 'community' },
  };

  const { gallery, home, myProjects } = explorerSubRoutes;
  const explorerSubNavItems = Object.values(explorerSubRoutes);

  return (
    <>
      {portalToHeader(() =>
        isLoggedIn ? (
          <ExplorerHeader navItems={explorerSubNavItems} activePath={activePath}>
            <AccountBar />
          </ExplorerHeader>
        ) : (
          <AccountBar />
        ),
      )}
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={gallery.path}>
            <GalleryPage />
          </Route>
          <ProtectedRoute authorized={isLoggedIn} path={myProjects.path}>
            <MyProjects />
          </ProtectedRoute>

          <Route path={home.path}>
            <Wrapper>
              <Content>
                <HomePage />
              </Content>
            </Wrapper>
          </Route>
        </Switch>
      </Suspense>
    </>
  );
}
