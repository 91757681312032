import {
  UserProperties,
  gtagIdentify,
  heapIdentify,
  logRocketIdentify,
  rudderIdentify,
  tracingIdentify,
} from './identify';

const services = [heapIdentify, gtagIdentify, tracingIdentify, rudderIdentify, logRocketIdentify];

export const setUserProperties = (id: string, userProperties: UserProperties) => {
  services.forEach((setUserProperties) => setUserProperties(id, userProperties));
};
