import type React from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (child: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
};

export function ConditionalWrapper({ condition, wrapper, children }: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}
