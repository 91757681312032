import type { PlayKitProject, View } from '@playful/playkit/playkit';
import {
  Component,
  ID,
  Project,
  Reactor,
  ReactorObjectBase,
  isProject,
  loadProject,
} from '@playful/runtime';
import { generateUUID } from '@playful/runtime/util';
import { sleep } from '@playful/utils';
import { canvasToBlob } from '@playful/utils/canvas';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

const thumbnailPx = 640;

export class ThumbnailService {
  constructor() {
    this.renderThumbnail = this.renderThumbnail.bind(this);
    this.attach = this.attach.bind(this);
    this.detach = this.detach.bind(this);
  }

  private addActiveProject?: RendererPoolMethods['addActiveProject'] = undefined;
  private removeActiveProject?: RendererPoolMethods['removeActiveProject'] = undefined;

  async renderThumbnail(view: Component | Project): Promise<Blob | undefined> {
    const sourceProject = isProject(view) ? view : view.project;

    if ((sourceProject as Reactor).noThumbnail) {
      console.log('skipping thumbnailing');
      return;
    }

    return this.withClonedProject(sourceProject, async (project) => {
      if (!isProject(view)) {
        const cloneView = project.getComponentById(view[ID]) as View;
        (project as PlayKitProject).setRootView(cloneView);
      }

      // TODO: The project takes a frame after mounting to get its CSS in place.
      // 1. Fix the project to get its CSS in place during mount.
      // 2. Provide a good way to wait for the project to be ready to render.
      await sleep(100);

      return this.renderProjectRootViewToCanvas(project);
    });
  }

  private async withClonedProject<T>(
    sourceProject: Project,
    handler: (project: Project) => Promise<T>,
  ): Promise<T> {
    if (!this.addActiveProject || !this.removeActiveProject) {
      throw new Error('RendererPool not attached');
    }

    const project = await this.cloneProject(sourceProject);
    project.thumbnailMode = true;

    const identifier = await this.addActiveProject(project);

    const result = await handler(project);

    this.removeActiveProject(identifier);

    return result;
  }

  private async renderProjectRootViewToCanvas(project: Project): Promise<Blob | undefined> {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      try {
        // Set transform to scale down to preview dimensions.
        // TODO: Formalize rootView?
        const rootViewWidth = (project as any).rootView.width;
        const rootViewHeight = (project as any).rootView.height;
        const scale = Math.min(1, thumbnailPx / Math.max(rootViewWidth, rootViewHeight));

        //console.log(`rootView w,h: ${rootViewWidth},${rootViewHeight}, scale: ${scale}`);
        canvas.width = rootViewWidth * scale;
        canvas.height = rootViewHeight * scale;
        context.scale(scale, scale);
        // TOOD: clear canvas?
        await project!.toCanvas(context);
        return canvasToBlob(canvas, 'image.png');
      } catch (err) {
        console.error(err);
      }
    }
    return undefined;
  }

  attach({ addActiveProject, removeActiveProject }: RendererPoolMethods) {
    if (this.addActiveProject) {
      throw new Error('RendererPool already attached');
    }

    this.addActiveProject = addActiveProject;
    this.removeActiveProject = removeActiveProject;
  }

  detach() {
    console.log('detaching from service');

    if (!this.addActiveProject) {
      throw new Error("Can't detach RendererPool. not attached.");
    }

    this.addActiveProject = undefined;
    this.removeActiveProject = undefined;
  }

  RendererPool = () => {
    return <RendererPool attach={this.attach} detach={this.detach} />;
  };

  private async cloneProject(sourceProject: Project): Promise<Project> {
    return await loadProject(
      sourceProject.getState(),
      sourceProject.info,
      undefined,
      sourceProject.resourceRoot,
    );
  }
}

const Renderer = ({
  identifier,
  onReady,
  project,
}: {
  identifier: string;
  onReady: (id: string) => void;
  project: Project;
}) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = container.current?.shadowRoot || container.current?.attachShadow({ mode: 'open' });

    let doneTimer: ReturnType<typeof setTimeout>;

    (project as any)._pendingRenders = project.createReactor({ count: 0 });

    const watcher = (project as any)._pendingRenders.onPropertyChange(
      (_: ReactorObjectBase, property: string, value: number) => {
        if (property === 'count' && value === 0) {
          if (doneTimer) {
            clearTimeout(doneTimer);
          }
          doneTimer = setTimeout(() => {
            watcher.dispose();
            onReady(identifier);
          }, 2);
        }
      },
    );

    // Save and restore the focused element across project.mount which has its own
    // ideas about where the focus should be.
    const activeElement = document.activeElement;

    project.mount(root as any as HTMLElement);

    (activeElement as HTMLElement)?.focus();

    return () => {
      project.unmount();
    };
  }, [identifier, onReady, project]);

  return (
    <div
      style={{ width: 300, height: 300 }}
      id={`project-player-${project.info.id}`}
      ref={container}
    />
  );
};

type RendererPoolProps = {
  attach(methods: RendererPoolMethods): void;
  detach(): void;
};

type RendererPoolMethods = {
  addActiveProject(project: Project): Promise<string>;
  removeActiveProject(identifier: string): void;
};

const RendererPool: FunctionComponent<RendererPoolProps> = ({ attach, detach }) => {
  const [activeProjects, setActiveProjects] = useState<{
    [identifier: string]: {
      identifier: string;
      project: Project;
      ready(id: string): void;
    };
  }>({});

  const addActiveProject = useCallback<RendererPoolMethods['addActiveProject']>((project) => {
    let ready: (id: string) => void;

    const promise = new Promise<string>((resolve) => {
      ready = resolve;
    });

    const identifier = generateUUID();

    setActiveProjects((projects) => ({
      ...projects,
      [identifier]: {
        identifier,
        project,
        ready: ready!,
      },
    }));

    return promise;
  }, []);

  const removeActiveProject = useCallback<RendererPoolMethods['removeActiveProject']>(
    (identifier) => {
      setActiveProjects((projects) => {
        const newProjects = { ...projects };

        delete newProjects[identifier];

        return newProjects;
      });
    },
    [],
  );

  useEffect(() => {
    attach({ addActiveProject, removeActiveProject });
    return detach;
  }, [addActiveProject, attach, detach, removeActiveProject]);

  return (
    <div
      style={{
        position: 'fixed',
        left: -5000,
        opacity: 0,
        pointerEvents: 'none',
      }}
    >
      {Object.values(activeProjects).map(({ identifier, project, ready }) => (
        <Renderer identifier={identifier} key={identifier} project={project} onReady={ready} />
      ))}
    </div>
  );
};
