import { useEffect, useState } from 'react';

/**
 * This hook sets the window title when a component gets mounted.
 * When the component gets unmounted the previously used title is restored.
 * @param {string} title
 */
export function useTitle(title: string): void {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;
    return () => {
      // Chrome has some kind of (popState?) race condition which makes just setting
      // document.title here unreliable. So do it on next tick.
      // Of course this will be a problem if we deliberately set the title
      // again before the timeout.
      setTimeout(() => (document.title = previousTitle), 1);
    };
  }, [title]);
}

// Thanks https://joshwcomeau.com/react/persisting-react-state-in-localstorage
// TODO: improve typing
export function useLocalStorageState(defaultValue: any, key: string) {
  const [value, setValue] = useState(() => {
    const localStorageValue = window.localStorage.getItem(key);
    try {
      return localStorageValue !== null ? JSON.parse(localStorageValue) : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
