import { createGenericContext } from '@playful/utils/createGenericContext';
import React, { PropsWithChildren } from 'react';

export type AppCommander = {
  newProject(): void;
};

export type AppProviderProps = {
  app: AppCommander;
};

const [hook, GenericProvider] = createGenericContext<AppCommander>();

export const useApp = hook;

export function AppProvider({ app, children }: PropsWithChildren<AppProviderProps>) {
  return <GenericProvider value={app}>{children}</GenericProvider>;
}
