import React from 'react';
import { Route, RouteProps } from 'wouter';

import { Redirect } from './Redirect';

export function PublicOnlyRoute({
  authorized,
  ...routeProps
}: RouteProps & { authorized?: boolean }) {
  return authorized ? (
    <Redirect to='/' query={window.location.search} />
  ) : (
    <Route {...routeProps} />
  );
}
