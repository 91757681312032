import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';

type TutorialStyleProps = {
  fixedPosition: boolean;
  hasAnchor: boolean;
  isMobileSafari: boolean;
  isSmallScreen: boolean;
};

export const useTutorialStyles = makeStyles((theme) =>
  createStyles({
    // tutorial step styles
    card: {
      zIndex: theme.zIndex.modal,
      margin: (p: TutorialStyleProps) => (p.hasAnchor ? 0 : theme.spacing(p.isSmallScreen ? 2 : 1)),
    },
    paper: {
      background: 'white',
      borderRadius: 16,
      maxWidth: 326,
      overflow: 'hidden',
      width: 326,
      margin: (p) => (p.hasAnchor ? undefined : 0),

      '& video': {
        borderRadius: (p) => (p.isMobileSafari ? '16px 16px 0px 0px' : undefined),
      },
    },

    // blocker styles
    backdrop: {
      zIndex: (p) => (p.fixedPosition ? theme.zIndex.modal : undefined),
    },
    background: {
      fill: 'rgba(0, 0, 0, 0.7)',
      x: 0,
      y: 0,
    },
    maskCallout: {
      fill: 'white',
      height: '100%',
      width: '100%',
      x: '0',
      y: '0',
    },
    svg: {
      height: '100%',
      left: (p) => (p.fixedPosition ? 0 : undefined),
      position: (p) => (p.fixedPosition ? 'fixed' : undefined),
      top: (p) => (p.fixedPosition ? 0 : undefined),
      width: '100%',
      zIndex: (p) => (p.fixedPosition ? theme.zIndex.modal : undefined),
    },

    // header styles
    welcomePlayer: {
      position: 'absolute',
      left: 20,
      width: 123,
      height: 123,
    },
    stylePlayer: {
      width: 300,
    },
    sharePlayer: {
      position: 'absolute',
      height: 180,
      width: 180,
      left: 73,
      top: -27,
    },
  }),
);

export const useTutorialBlockerStyles = useTutorialStyles as (
  p: Pick<TutorialStyleProps, 'fixedPosition'>,
) => ClassNameMap<'backdrop' | 'background' | 'maskCallout' | 'svg'>;

export const useTutorialStepStyles = useTutorialStyles as (
  p: Pick<TutorialStyleProps, 'hasAnchor' | 'isMobileSafari' | 'isSmallScreen'>,
) => ClassNameMap<'card' | 'paper'>;

export const useTutorialHeaderStyles = useTutorialStyles as () => ClassNameMap<
  'welcomePlayer' | 'stylePlayer' | 'sharePlayer'
>;
