import React from 'react';
import { RedirectProps, Redirect as WRedirect } from 'wouter';

export function Redirect({
  query = '',
  to,
  ...props
}: RedirectProps & { to: string; query: string }) {
  const fullPath = `${to}${query}`;

  return <WRedirect to={fullPath} {...props} />;
}
