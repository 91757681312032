import LogRocket from 'logrocket';

export function shareSession() {
  const { heap, gtag, rudderanalytics } = window;
  LogRocket.getSessionURL((sessionURL) => {
    heap?.track('LogRocket', { sessionURL });
    gtag?.('event', 'sessionURL', {
      eventCategory: 'LogRocket',
      sessionURL,
    });
    rudderanalytics?.track('LogRocket', { url: sessionURL });
  });
}
